import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  component: {
    left: '0',
    right: '0',
    bottom: '0',
    position: 'fixed',
    overflow: 'hidden',
    zIndex: '100000',
    backgroundColor: ({ theme }) => theme.PrimaryColour,
  },
  container: {
    maxWidth: '900px',
    margin: 'auto',
    padding: '15px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
  },
  message: {
    fontSize: '12px',
    lineHeight: '1.4',
    userSelect: 'none',
    color: ({ theme }) => theme.PrimaryColourOverlay,
    textAlign: ({ isMobile }) => (isMobile ? 'center' : 'unset'),
  },
  messageLink: {
    textDecoration: 'underline',
    fontWeight: '700',
    userSelect: 'none',
    color: ({ theme }) => theme.PrimaryColourOverlay,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: ({ isMobile }) => (isMobile ? '20px' : 'unset'),
    marginLeft: ({ isMobile }) => (isMobile ? 'unset' : '30px'),
  },
  button: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '1',
    width: '130px',
    height: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    borderColor: ({ theme }) => theme.PrimaryColourOverlay,
  },
  buttonAccept: {
    fontWeight: '700',
    textTransform: 'uppercase',
    color: ({ theme }) => theme.PrimaryColour,
    backgroundColor: ({ theme }) => theme.PrimaryColourOverlay,
  },
  buttonSettings: {
    marginLeft: '15px',
    color: ({ theme }) => theme.PrimaryColourOverlay,
    backgroundColor: ({ theme }) => theme.PrimaryColour,
  },
  buttonMobile: {
    height: '20px',
    border: 'unset',
    fontWeight: '700',
    textTransform: 'uppercase',
    color: ({ theme }) => theme.PrimaryColourOverlay,
  },
});

export default useStyles;
