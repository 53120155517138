import { GET_TICKET } from '../_types';

const initialState = {};

const ticketReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TICKET: {
      return payload;
    }

    default:
      return state;
  }
};

export default ticketReducer;
