import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';

const Route404 = () => {
  const [canShow, setCanShow] = useState(false);

  // This is the current fix to stop the 404 route showing
  // whilst app is loading - AF
  // it's a bit hacky and potentially causes an unnecessary re-render
  useEffect(() => {
    const timer = setTimeout(() => {
      setCanShow(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (!canShow) {
    return null;
  }

  return (
    <React.Fragment>
      <Container>
        <h1>404 - Pages does not exist</h1>
      </Container>
    </React.Fragment>
  );
};

export default Route404;
