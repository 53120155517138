import { Helmet } from 'react-helmet';
import React from 'react';

const HelmetScripts = (props) => {
  return (
    <Helmet>
      <title>Shift - Booking</title>

      <script
        type='text/javascript'
        src='https://maps.googleapis.com/maps/api/js?key=AIzaSyD1xV9ggoY_nPICr9jks15aAyHC162BuV8&amp;libraries=geometry'
        defer
      />

      <style>
        {`  .slick-slide {
          margin: 0 5px;
          }
          /* the parent */
          .slick-list {
          margin: 0 -5px;
          }`}
      </style>

      <script>
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'UA-71048195-3', { 'optimize_id': 'GTM-WTBPJFW'});
      gtag('config', 'AW-932404788');

      window['GoogleAnalyticsObject'] = 'ga';
      window['ga'] = window['ga'] || function() {
        (window['ga'].q = window['ga'].q || []).push(arguments)
      };
  `}
      </script>

      <script>
        {`
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.defer=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '190098278206692');
    fbq('track', 'PageView');
  `}
      </script>
      <noscript>
        {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=190098278206692&ev=PageView&noscript=1"/>`}
      </noscript>

      <script>
        {`
    fbq('track', 'Lead');
  `}
      </script>
      <style>
        {`
    body{
      background-color: #f9fafc;
    }
  `}
      </style>
    </Helmet>
  );
};

export default HelmetScripts;
