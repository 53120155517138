import {
  INIT_FOOTER_ACTION,
  SET_TEXT_FOOTER_ACTION,
  SET_FOOTER_HIDDEN_ACTION,
  SET_ADDITIONAL_HEADER,
  SET_BUTTON_ACTION_FOOTER_ACTION,
  SET_BUTTON_DISABLED_FOOTER_ACTION,
  SET_BUTTON_SPINNING_FOOTER_ACTION,
  INIT_HEADER_LINK_ACTION,
  SET_FOOTER_ACTION_MAX_WIDTH,
} from '../_types';

const initialState = {
  footerAction: {
    show: false,
    textComponent: null,
    buttonDisabled: true,
    maxWidth: 750,
  },
  additionalHeader: {
    show: false,
    text: '',
  },
  headerAction: {
    link: false,
  },
};

const summary = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_FOOTER_ACTION: {
      return {
        ...state,
        footerAction: payload,
      };
    }

    case INIT_HEADER_LINK_ACTION: {
      return {
        ...state,
        headerAction: payload,
      };
    }

    case SET_TEXT_FOOTER_ACTION: {
      return {
        ...state,
        footerAction: {
          ...state.footerAction,
          textComponent: payload,
        },
      };
    }

    case SET_FOOTER_HIDDEN_ACTION: {
      return {
        ...state,
        footerAction: {
          ...state.footerAction,
          hidden: payload,
        },
      };
    }

    case SET_BUTTON_ACTION_FOOTER_ACTION: {
      return {
        ...state,
        footerAction: {
          ...state.footerAction,
          buttonAction: payload,
        },
      };
    }

    case SET_BUTTON_DISABLED_FOOTER_ACTION: {
      return {
        ...state,
        footerAction: {
          ...state.footerAction,
          buttonDisabled: payload,
        },
      };
    }

    case SET_ADDITIONAL_HEADER: {
      return {
        ...state,
        additionalHeader: payload,
      };
    }

    case SET_BUTTON_SPINNING_FOOTER_ACTION: {
      return {
        ...state,
        footerAction: {
          ...state.footerAction,
          buttonSpinning: payload,
        },
      };
    }

    case SET_FOOTER_ACTION_MAX_WIDTH: {
      return {
        ...state,
        footerAction: {
          ...state.footerAction,
          maxWidth: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default summary;
