import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import IconSearch from '../assets/images/icon_search.svg';

const useStyles = makeStyles({
  root: {
    color: (state) => state.theme.PrimaryColour,
    maxWidth: 750,
    margin: '0 auto',
    zIndex: -1,
    '@media only screen and (max-width: 500px)': {
      padding: '0 0 40px 0',
    },
    overflow: 'hidden',
    paddingBottom: 40,
  },
  uiStepper: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: ({ showStepper }) => {
      if (showStepper) return 1;
      return 0;
    },
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 28,
      position: 'relative',
      background: (state) => state.theme.TertiaryColour,
      borderRadius: 14,
      color: 'rgba(0, 0, 0, 0.75)',
      paddingLeft: 10,
      paddingRight: 20,
      marginLeft: 10,
      marginRight: 10,
      fontSize: 11,
      '& span': {
        marginLeft: 10,
      },
      '&.active': {
        color: (state) => state.theme.TertiaryColour,
        background: (state) => state.theme.SecondaryColour,
      },
    },
  },
  search: {
    background: 'rgba(227, 231, 238, 0.5)',
    borderRadius: 14,
    minHeight: 72,
    position: 'relative',
    '& input': {
      background: (state) => state.theme.TertiaryColour,
      boxShadow: '0px 1px 10px rgba(214, 214, 214, 0.75)',
      borderRadius: 8,
      border: 'none',
      marginLeft: 22,
      marginTop: 14,
      padding: 20,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 45,
      color: (state) => state.theme.QuaternaryColour,
      width: '73%',
      float: 'left',
    },
    '& img': {
      position: 'absolute',
      width: 20,
      height: 20,
      left: 35,
      top: 25,
      zIndex: 999,
    },
  },
  buttonSearch: {
    cursor: 'pointer',
    background: (state) => state.theme.SecondaryColour,
    backgroundImage: `url(${IconSearch})`,
    borderRadius: 8,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    paddingTop: 21,
    marginTop: 13,
    marginLeft: 20,
    float: 'left',
    width: '16%',
    height: 47,
  },
});

const Body = React.memo((props) => {
  const { showStepper, service, theme } = props;
  const classes = useStyles({ showStepper, theme });

  if (service === 'ShiftShopping') {
    return (
      <div id={'bodyRoot'} className={classes.root}>
        {props.children}
      </div>
    );
  }

  return (
    <div id={'bodyRoot'} className={classes.root}>
      {props.children}
    </div>
  );
});

Body.propTypes = {};

const mapStateToProps = (state) => {
  const { showStepper } = state.rootreducer.ui;
  const { theme } = state.config;
  return { showStepper, theme };
};

const mapDispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Body));
