import {
  CAPTURE_PHONE_START,
  CAPTURE_PHONE_SET,
  CAPTURE_PHONE_ERROR,
  CAPTURE_PHONE_END,
} from '../_types';

const { REACT_APP_ENDPOINT } = process.env;

export const fetchCapturePhone = () => async (dispatch, getState) => {
  const state = getState();

  if (!state.capturePhone.requesting) {
    dispatch({ type: CAPTURE_PHONE_START });
    try {
      const url = `${REACT_APP_ENDPOINT}/shiftonline/frontend_capture_phone`;
      const response = await fetch(url);

      if (response.status !== 200) {
        dispatch({
          type: CAPTURE_PHONE_ERROR,
          payload: 'Failed to get config',
        });
      }

      const { result } = await response.json();

      const {
        frontend_capture_phone,
        frontend_capture_phone_close_window,
      } = result;

      dispatch({
        type: CAPTURE_PHONE_SET,
        payload: {
          shouldCapture: frontend_capture_phone,
          closeWindow: frontend_capture_phone_close_window,
        },
      });
    } catch (error) {
      dispatch({ type: CAPTURE_PHONE_ERROR, payload: error });
    } finally {
      dispatch({ type: CAPTURE_PHONE_END });
    }
  }
};
