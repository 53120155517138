import { SET_NAV_PILL, SET_CASE_STUDIE, SET_TIMESLOT_STRING } from '../_types';

const ENV_DEVELOPMENT = 'ENV_DEVELOPMENT';
const ENV_PRODUCTION = 'ENV_PRODUCTION';
const APP_PATH = 'APP_PATH';

const AppPath = (payload) => {
  return {
    type: APP_PATH,
    payload,
  };
};

export const mixPanelStart = (payload) => (dispatch) => {
  dispatch({
    type: 'MIX_PANEL_START',
    payload,
  });
};

export const setNavPill = (payload) => (dispatch) => {
  dispatch({
    type: SET_NAV_PILL,
    payload,
  });
};

export const setTimeslotString = (payload) => (dispatch) => {
  dispatch({
    type: SET_TIMESLOT_STRING,
    payload,
  });
};

export const setCaseStudie = (payload) => (dispatch) => {
  dispatch({
    type: SET_CASE_STUDIE,
    payload,
  });
};

export default {
  ENV_DEVELOPMENT,
  ENV_PRODUCTION,
  APP_PATH,
  AppPath,
};
