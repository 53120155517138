import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';

import HelmetScripts from './components/HelmetScripts';

import Header from './components/Header';
import Body from './components/Body';
import CapturePhonePopupHandler from './components/CapturePhonePopupHandler';
import Popup from './components/Popup';
import AdditionalHeader from './components/AdditionalHeader';
import FooterAction from './components/FooterAction';
import ScrollToTop from './components/ScrollToTop';

import getRouteLanguage from '../../_functions/getRouteLanguage';

window.triggered = false;
const useStyles = makeStyles({
  root: {
    backgroundColor: '#f9fafc',
    transition: '0.5s',
    msTransition: '0.5s',
    MozTransition: '0.5s',
    WebkitTransition: '0.5s',
  },
  fadeIn: {
    filter: 'blur(5px)',
    msFilter: 'blur(5px)',
    WebkitFilter: 'blur(5px)',
    '@media only screen and (max-width: 500px)': {
      filter: 'blur(0px)',
      msFilter: 'blur(0px)',
      WebkitFilter: 'blur(0px)',
    },
  },
});

const Booking = React.memo((props) => {
  const { ticket, additionalHeader, popupVisible, theme } = props;

  const classes = useStyles();

  const getRootClassNames = () => {
    if (popupVisible) {
      document.body.style.position = 'fixed';
      document.body.style.left = '0';
      document.body.style.right = '0';
      return classNames(classes.root, classes.fadeIn);
    } else {
      document.body.style.position = '';
    }
    return classes.root;
  };

  if (ticket && ticket.id && ticket.TicketType !== 'Hold') {
    return <Redirect to={`/${getRouteLanguage()}/tracking/${ticket.id}`} />;
  }

  return (
    <Fragment>
      <div className={getRootClassNames()}>
        <HelmetScripts />
        <Header />

        {additionalHeader.show && (
          <AdditionalHeader text={additionalHeader.text} />
        )}

        <Body service={ticket.service}>{props.children}</Body>
        <FooterAction />
        <ScrollToTop />
      </div>

      {theme.ShowPhonePopup ? <CapturePhonePopupHandler /> : null}

      {popupVisible && <Popup />}
    </Fragment>
  );
});

const mapStateToProps = (state) => {
  const { theme } = state.config;
  const { ui, ticket } = state.rootreducer;
  const popupVisible = state.popup.showPopup;
  const { additionalHeader } = state.summaryReducer;
  return { ui, ticket, additionalHeader, popupVisible, theme };
};

export default connect(mapStateToProps, null)(Booking);
