import React, { useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { connect } from 'react-redux';

import StripeInput from '../StripeInput';

import styles from './styles';

const useStyles = makeStyles(styles);

const FieldInput = (props) => {
  const selectRef = useRef(null);
  const {
    labelText,
    onChange,
    value,
    // helperText,
    onFocus,
    select,
    selectItems,
    renderValue,
    endAdornment,
    stripeElement,
    disabled,
    inputType,
    renderLabel,
    error,
    theme,
  } = props;

  const helperText = props.helperText
    ? props.helperText.replace(/\.$/, '')
    : undefined;

  const classes = useStyles({ theme });

  const handleChange = (event) => {
    onChange(event);
    if (select) {
      setTimeout(() => {
        selectRef.current.firstChild.blur();
      }, 100);
    }
  };

  let stripeProps = {};
  if (stripeElement) {
    stripeProps = {
      inputProps: {
        component: stripeElement,
      },
      inputComponent: StripeInput,
    };
  }

  const labelFix = endAdornment
    ? {
        focused: true,
        shrink: true,
      }
    : {};

  return (
    <TextField
      disabled={disabled}
      autoComplete='off'
      select={select}
      helperText={helperText}
      label={labelText}
      variant='outlined'
      FormHelperTextProps={{
        classes: {
          root: classes.errorTxt,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.rootField,
        },
        ...labelFix,
      }}
      InputProps={{
        classes: {
          root: clsx(classes.rootField, {
            [classes.errorAnimation]: error,
          }),
          // focused: classes.focused
        },
        // notchedOutline: classes.rootFieldBorder,

        endAdornment: endAdornment ? endAdornment : null,
        type: inputType ? inputType : 'text',
        ...stripeProps,
      }}
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
      SelectProps={{
        inputProps: {
          name: 'test',
        },
        MenuProps: {
          classes: {
            list: classes.menuText,
          },
        },
        renderValue: (value) => {
          if (renderValue) {
            return renderValue(value);
          }

          return value;
        },
        ref: selectRef,
      }}
      className={classes.root}
      classes={{ root: classes.focused }}
    >
      {select &&
        selectItems.map((item, key) => {
          const { id, label } = item;

          if (renderLabel) {
            return (
              <MenuItem key={key} value={label}>
                {label}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={key} value={id}>
              {label}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

const mapStateToProps = (state) => {
  const { theme } = state.config;
  return { theme };
};

export default connect(mapStateToProps, null)(FieldInput);
