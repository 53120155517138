import { SMARTLOOK_INIT, SMARTLOOK_TRACK, SMARTLOOK_DISABLE } from '../_types';
import smartlookClient from 'smartlook-client';

import { updateTicket } from './ticket.action';

export const smartlookInit = (payload) => (dispatch) => {
  if (!window.smartlook) {
    if (/shift\.online$/.test(window.location.host)) {
      // only initialise for production environments
      smartlookClient.init('d9764202842a9d14c49ea6b229fc6a1fcd1c01f2');
    } else {
      // noop
      window.smartlook = (...args) => {};
    }
  }

  dispatch({
    type: SMARTLOOK_INIT,
    payload,
  });
};

export const smartlookTrack = (payload, metaTracking) => (dispatch) => {
  if (metaTracking) {
    const { ticket, visited, entries } = metaTracking;

    if (ticket && (visited || entries)) {
      // make sure the ticket has been created
      if (ticket.id) {
        const meta = ticket.meta || {};
        const tracking = meta.tracking || {};

        let changes = {};

        if (visited) {
          const entry = visited + '_visited';

          if (tracking[entry] !== undefined) {
            // avoid unnecessary ticket updates
            return;
          }

          changes = {
            meta: { ...meta, tracking: { ...tracking, [entry]: true } },
          };
        } else {
          changes = {
            meta: { ...meta, tracking: { ...tracking, ...entries } },
          };
        }

        updateTicket(ticket.id, changes)(dispatch);
      }
    } else {
      console.error('smartlook meta tracking is missing required values');
    }
  }

  // payload may be null if we only need to update the meta tracking
  if (payload === null) {
    return;
  }

  if (payload.number) {
    smartlookClient.track(payload.type, {
      ticket: payload.ticketId,
      number: payload.phone,
      customerId: payload.customerId,
    });
  } else {
    smartlookClient.track(payload.type, {
      ticket: payload.ticketId,
    });
  }

  dispatch({
    type: SMARTLOOK_TRACK,
    payload,
  });
};

export const smartlookDisable = (payload) => (dispatch) => {
  smartlookClient.disable();

  dispatch({
    type: SMARTLOOK_DISABLE,
    payload,
  });
};
