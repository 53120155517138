import {
  SET_TWO_SHIFTERS,
  SET_THEME,
  SET_CURRENT_BOOKER_STEP,
  TOGGLE_STEPPER_OPACITY,
} from '../_types';

const initialState = {
  useTwoShifters: false,
  theme: 'default',
  currentBookingStep: 2,
  showStepper: true,
};

const uiReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_BOOKER_STEP: {
      return {
        ...state,
        currentBookingStep: payload,
      };
    }

    case SET_TWO_SHIFTERS: {
      return {
        ...state,
        useTwoShifters: payload,
      };
    }

    case SET_THEME: {
      return {
        ...state,
        theme: payload,
      };
    }

    case TOGGLE_STEPPER_OPACITY: {
      return {
        ...state,
        showStepper: payload,
      };
    }

    default:
      return state;
  }
};

export default uiReducer;
