import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import PurpleButton from '../PurpleButton';
import useSafariMenuState from '../../../../_hooks/useSafariMenuState';

// import styles from './styles';

const useStyles = makeStyles({
  root: {
    left: 0,
    right: 0,
    bottom: (state) => {
      if (state.cookieBannerVisible) {
        return state.cookieBannerHeight - 1 + 'px';
      }

      return '0';
    },
    position: 'fixed',
    background: 'white',
    boxShadow: 'rgba(42, 40, 65, 0.12) 0px -5px 10px',
    transition: 'all 0.2s linear',
    zIndex: 10001,
    borderBottomColor: '#ffffff',
    borderBottomStyle: 'solid',
    // borderBottomWidth: ({ safariMenuVisible }) =>
    //   safariMenuVisible ? '0px' : '0px',
  },
  container: {
    overflow: 'hidden',
    minHeight: '80px',
    width: '100%',
    maxWidth: (props) => props.maxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      alignItems: 'center',
      flexDirection: 'row',
    },
    '@media (min-width: 750px)': {
      paddingLeft: 'unset',
      paddingRight: 'unset',
    },
  },
  textContainer: {
    flex: '1',
    '@media only screen and (max-width: 599px)': {
      width: '40%',
      padding: '10px',
    },
  },
  buttonContainer: {
    paddingTop: '8px',
    '@media (min-width: 600px)': {
      width: '200px',
      paddingTop: 'unset',
      paddingLeft: '10px',
    },
    '@media only screen and (max-width: 599px)': {
      padding: '10px 15px',
      '& > div': {
        textAlign: 'center',
      },
    },
  },
  buttonContainerWithPrice: {
    '@media only screen and (max-width: 599px)': {
      width: '60%',
      right: '0px',
      position: 'absolute',
      bottom: '7px',
    },
  },
});

const FooterAction = (props) => {
  const { footerAction, cookieBannerHeight, cookieBannerVisible } = props;
  const {
    show,
    hidden,
    buttonAction,
    buttonDisabled,
    textComponent,
    continueButtonText,
    maxWidth,
  } = footerAction;

  const safariMenuVisible = useSafariMenuState();

  const classes = useStyles({
    maxWidth,
    safariMenuVisible,
    cookieBannerHeight,
    cookieBannerVisible,
  });

  const [loading, setLoading] = useState(false);

  // we need a default handler for the button in case buttonAction is undefined
  const handleButtonClick = useCallback(() => {
    if (typeof buttonAction === 'function') {
      setLoading(true);
      setTimeout(() => {
        buttonAction();
        setTimeout(() => {
          setLoading(false);
        }, 300);

        return;
      }, 300);
    }

    console.debug('FooterAction button clicked (no handler defined)');
  }, [buttonAction]);

  if (!show) return null;

  const visibleStyle = hidden
    ? {
        marginBottom: safariMenuVisible ? -170 : -120,
      }
    : {
        marginBottom: 0,
      };

  return (
    <div id={'footerActionRoot'} className={classes.root} style={visibleStyle}>
      <div id={'footerActionContainer'} className={classes.container}>
        <div id={'footerActionTextContainer'} className={classes.textContainer}>
          {textComponent}
        </div>
        <div
          className={[
            classes.buttonContainer,
            textComponent ? classes.buttonContainerWithPrice : null,
          ].join(' ')}
        >
          <PurpleButton
            text={continueButtonText ? continueButtonText : 'Continue'}
            arrowType='right'
            textAlign='left'
            onClick={handleButtonClick}
            disabled={buttonDisabled}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

FooterAction.propTypes = {};

const mapStateToProps = (state) => {
  return {
    footerAction: state.summaryReducer.footerAction,
    cookieBannerHeight: state.cookieBanner.height,
    cookieBannerVisible: state.cookieBanner.visible,
  };
};
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(FooterAction);
