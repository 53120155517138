import { SET_NAV_PILL, SET_CASE_STUDIE, SET_TIMESLOT_STRING } from '../_types';
import appServices from '../_services/app.service';
import appAction from '../_actions/app.action';

const initialState = {
  dev: appServices.envDevelopment(),
  beta: appServices.isBeta() || true,
  path: window.location.pathname,
  tracking: {},
  navPill: '',
  caseStudy: '',
  type: '',
  catIds: '',
  childTitle: '',
  childItems: '',
  timeSlotString: '',
};

const app = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case appAction.APP_PATH:
      return {
        ...state,
        path: action.payload,
      };

    case SET_CASE_STUDIE: {
      return {
        ...state,
        caseStudy: payload[0],
        type: payload[1],
        catIds: payload[2],
        childTitle: payload[3],
        childItems: payload[4],
        // navPill: payload,
      };
    }

    case SET_NAV_PILL: {
      return {
        ...state,
        navPill: payload,
      };
    }

    case SET_TIMESLOT_STRING: {
      return {
        ...state,
        timeSlotString: payload,
      };
    }
    // case 'MIX_PANEL_START':
    //     return {
    //         ...state,
    //         tracking: action.payload
    //     }
    default:
      return state;
  }
};

export default app;
