const { REACT_APP_ENDPOINT } = process.env;

const fetchAddress = async (search) => {
  try {
    const response = await fetch(`${REACT_APP_ENDPOINT}/shiftonline/postcode/search`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ search }), 
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export default fetchAddress;