import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Booking from './Booking';

const InventoryCaseStudie = React.lazy(() =>
  import('./Booking/components/InventoryCaseStudie')
);
const Services = React.lazy(() => import('./Booking/components/Services'));
const TimeslotSelection = React.lazy(() =>
  import('./Booking/components/TimeslotSelection')
);
const InventoryCaseStudieList = React.lazy(() =>
  import('./Booking/components/InventoryCaseStudyList')
);
const SelectStore = React.lazy(() =>
  import('./Booking/components/Tabs/SelectStore')
);
const ShoppingList = React.lazy(() =>
  import('./Booking/components/Tabs/ShoppingList')
);

const BookingRouteHandler = (props) => {
  const { bookerTicket } = props;

  if (Object.keys(bookerTicket).length === 0) {
    return null;
  }

  return (
    <Switch>
      {bookerTicket.service === 'ShiftShopping' ? (
        <Route
          exact
          path={'/:lang/booking/:id'}
          component={(props) => (
            <Booking {...props}>
              <Suspense fallback={<div />}>
                <SelectStore />
              </Suspense>
            </Booking>
          )}
        />
      ) : (
        <Route
          exact
          path={'/:lang/booking/:id'}
          component={(props) => (
            <Booking {...props}>
              <Suspense fallback={<div />}>
                <InventoryCaseStudie />
              </Suspense>
            </Booking>
          )}
        />
      )}
      <Route
        exact
        path={'/:lang/booking/:id/shopping-list'}
        component={(props) => (
          <Booking {...props}>
            <ShoppingList />
          </Booking>
        )}
      />
      <Route
        exact
        path={'/:lang/booking/:id/inventory'}
        component={(props) => (
          <Booking {...props}>
            <Suspense fallback={<div />}>
              <InventoryCaseStudieList />
            </Suspense>
          </Booking>
        )}
      />
      <Route
        exact
        path='/:lang/booking/:id/services'
        component={(props) => (
          <Booking {...props}>
            <Suspense fallback={<div />}>
              <Services />
            </Suspense>
          </Booking>
        )}
      />
      <Route
        exact
        path={'/:lang/booking/:id/delivery-slot'}
        component={(props) => (
          <Booking {...props}>
            <Suspense fallback={<div />}>
              <TimeslotSelection />
            </Suspense>
          </Booking>
        )}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  const { bookerTicket } = state;
  return { bookerTicket };
};

export default connect(mapStateToProps, null)(BookingRouteHandler);
