import {
  CAPTURE_PHONE_END,
  CAPTURE_PHONE_START,
  CAPTURE_PHONE_SET,
} from '../_types';

const initialState = {
  requesting: false,
  shouldCapture: false,
  closeWindow: false,
};

const capturePhoneReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CAPTURE_PHONE_START: {
      return {
        ...state,
        requesting: true,
      };
    }
    case CAPTURE_PHONE_END: {
      return {
        ...state,
        requesting: false,
      };
    }
    case CAPTURE_PHONE_SET: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
};

export default capturePhoneReducer;
