import {
  SNIPPIT_SET_PICKUP_DATA,
  SNIPPIT_SET_DROPOFF_DATA,
  SNIPPIT_SET_ACTIVE_INPUT,
  SNIPPIT_SEARCH_START,
  SNIPPIT_SEARCH_END,
  SNIPPIT_SEARCH_RESULTS,
} from '../_types';
import formatAddress from '../_functions/formatAddress';

const initialState = {
  pickupData: {},
  dropOffData: {},
  type: 'move',
  activeInput: '',
  pickupAddress: '',
  dropOffAddress: '',
  dropAddressPicked: false,
  pickAddressPicked: false,
  requesting: false,
  loadingAddresses: false,
  searchResults: [],
};

const snippit = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SNIPPIT_SET_ACTIVE_INPUT: {
      return {
        ...state,
        activeInput: payload,
        searchResults: [],
      };
    }
    case SNIPPIT_SET_PICKUP_DATA: {
      const data = formatAddress(payload);
      return {
        ...state,
        pickupData: payload,
        pickupAddress: `${data.addressShort} ${data.address}`,
        pickAddressPicked: true,
      };
    }
    case SNIPPIT_SET_DROPOFF_DATA: {
      const data = formatAddress(payload);
      return {
        ...state,
        dropOffData: payload,
        dropOffAddress: `${data.addressShort} ${data.address}`,
        dropAddressPicked: true,
      };
    }
    case SNIPPIT_SEARCH_START: {
      const { activeInput } = state;
      const dataLabel = activeInput === 'pick' ? 'pickupData' : 'dropOffData';
      const addressLabel =
        activeInput === 'pick' ? 'pickupAddress' : 'dropOffAddress';
      return {
        ...state,
        loadingAddresses: true,
        searchResults: [],
        [payload.activeInputAddressPicked]: false,
        [dataLabel]: {},
        [addressLabel]: '',
      };
    }
    case SNIPPIT_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: payload.addresses,
      };
    }
    case SNIPPIT_SEARCH_END: {
      return { ...state, loadingAddresses: false };
    }
    default: {
      return state;
    }
  }
};

export default snippit;
