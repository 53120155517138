import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import BookingRouteHandler from './BookingRouteHandler';
import DataLoader from './DataLoader';
import Route404 from './404';
import DynamicContentHandler from './DynamicContentHandler';

const Tracking = React.lazy(() => import('./TrackingShift/index'));
const Faq = React.lazy(() => import('./Faq'));
const Snippit = React.lazy(() => import('./Snippit'));
const BlogComponent = React.lazy(() => import('./BlogComponent'));

const Location = React.lazy(() => import('./Location'));

const Routes = (props) => {
  const { theme, location } = props;

  return (
    <React.Fragment>
      <DataLoader />
      <Switch>
        {/* <Redirect exact path={'/:lang'} to={`/:lang/`} /> */}
        <Route path={'/:lang/booking/'} component={BookingRouteHandler} />
        <Route
          key={'/:lang/tracking/'}
          path='/:lang/tracking/:id'
          component={() => (
            <Suspense fallback={<div />}>
              <Tracking />
            </Suspense>
          )}
        />

        <Route
          exact
          key={'/:lang/blog'}
          path='/:lang/blog'
          component={() => (
            <Suspense fallback={<div />}>
              <BlogComponent />
            </Suspense>
          )}
        />
        <Route
          key={'/faq'}
          exact
          path='/faq'
          component={() => (
            <Suspense fallback={<div />}>
              <Faq />
            </Suspense>
          )}
        />
        <Route
          key={'/snippit'}
          exact
          path='/snippit'
          component={() => (
            <Suspense fallback={<div />}>
              <Snippit />
            </Suspense>
          )}
        />
        <Route
          key={'/:lang/location'}
          path='/:lang/location'
          component={() => (
            <Suspense fallback={<div />}>
              <Location />
            </Suspense>
          )}
        />
        {location.pathname === '/' ? <Redirect to='/gb-en/' /> : null}
        {theme.Domain &&
          theme.Domain !== 'shift' &&
          theme.Domain !== 'beta' && (
            <Redirect exact path={'/:lang/'} to={`/:lang/location`} />
          )}
        <DynamicContentHandler />
        <Route component={Route404} />
      </Switch>
      <Helmet>
        {theme && theme.ThemeCSS ? <style>{`${theme.ThemeCSS}`}</style> : null}
      </Helmet>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { theme } = state.config;
  return { theme };
};

export default withRouter(connect(mapStateToProps, null)(Routes));
