import { SET_TIMESLOTS, UPDATE_TIMESLOTS } from '../_types';

const initialState = false;

const timeslotsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TIMESLOTS: {
      return payload;
    }

    case UPDATE_TIMESLOTS: {
      return [...state, ...payload];
    }

    default:
      return state;
  }
};

export default timeslotsReducer;
