import {
  LOCATION_GET_IKEA_STORES,
  LOCATION_SET_IKEA_STORES,
  LOCATION_SET_PICKUP,
  LOCATION_SET_DROPOFF,
} from '../_types';

const initialState = {
  ikeaStores: [],
  pickup: {},
  dropoff: {},
};

const locationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOCATION_GET_IKEA_STORES: {
      return initialState;
    }
    case LOCATION_SET_IKEA_STORES: {
      return { ...state, ikeaStores: payload.stores };
    }
    case LOCATION_SET_PICKUP: {
      return { ...state, pickup: payload };
    }
    case LOCATION_SET_DROPOFF: {
      return { ...state, dropoff: payload };
    }
    default: {
      return state;
    }
  }
};

export default locationReducer;
