import { CHECK_SERVICES_SET } from '../_types';

const servicesReducer = (state = null, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHECK_SERVICES_SET: {
      return [...payload];
    }
    default: {
      return { ...state };
    }
  }
};

export default servicesReducer;
