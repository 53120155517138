const { REACT_APP_ENDPOINT } = process.env;

const createOrGetCustomer = async (customerPayload) => {
  try {
    const response = await fetch(`${REACT_APP_ENDPOINT}/shiftonline/customer/create`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(customerPayload),
    });

    if (response.status !== 200) {
      throw new Error(response);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default createOrGetCustomer;