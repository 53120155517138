import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import {
  setCookiesAccepted,
  setCookieBannerHeight,
} from '../../_actions/cookieBanner.action';

import useIsMobile from '../../_hooks/useIsMobile';
import useStyles from './styles';

const CookieBanner = (props) => {
  const { theme, visible, setCookiesAccepted, setCookieBannerHeight } = props;

  const isMobile = useIsMobile();
  const componentElement = useRef(null);

  const [showSettingsButton] = useState(false);

  useEffect(() => {
    const syncHeight = () => {
      if (componentElement.current) {
        setCookieBannerHeight(componentElement.current.offsetHeight);
      }
    };

    syncHeight();

    window.addEventListener('resize', syncHeight, { passive: true });

    return () => {
      window.removeEventListener('resize', syncHeight);
    };
  }, [setCookieBannerHeight]);

  const handleSettingsClick = () => {
    //
  };

  const classes = useStyles({ theme, isMobile });

  if (visible === false) {
    return null;
  }

  return (
    <div className={classes.component} ref={componentElement}>
      <div className={classes.container}>
        <div className={classes.message}>
          {isMobile ? (
            <span>
              By continuing to use this website you consent to the use of
              cookies in accordance with our{' '}
            </span>
          ) : (
            <span>
              We use cookies to offer you a better experience, analyze site
              traffic and serve targeted advertisments. By continuing to use
              this website you consent to the use of cookies in accordance with
              our{' '}
            </span>
          )}
          <a
            href='#cookie-policy'
            target='_blank'
            className={classes.messageLink}
          >
            Cookie Policy
          </a>
        </div>
        <div className={classes.buttonContainer}>
          <div
            className={
              classes.button +
              ' ' +
              (isMobile ? classes.buttonMobile : classes.buttonAccept)
            }
            onClick={setCookiesAccepted}
          >
            Accept Cookies
          </div>
          {showSettingsButton ? (
            <div
              className={classes.button + ' ' + classes.buttonSettings}
              onClick={handleSettingsClick}
            >
              Cookie Settings
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

CookieBanner.displayName = 'cookie-banner';
CookieBanner.propTypes = {};

const mapStateToProps = (state) => {
  return {
    theme: state.config.theme,
    visible: state.cookieBanner.visible,
  };
};

const mapDispatchToProps = {
  setCookiesAccepted,
  setCookieBannerHeight,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner);
