import { useCallback, useEffect, useRef, useState } from 'react';

function isPortrait() {
  return (
    document.documentElement.clientWidth < document.documentElement.clientHeight
  );
}

function isSafari() {
  return (
    window.navigator.userAgent.indexOf('iPhone') > 0 &&
    window.navigator.userAgent.indexOf('Safari') > 0
  );
}

function useSafariMenuState() {
  const scrollDelta = useRef(0);
  const scrollPosition = useRef(0);

  const [menuVisible, setMenuVisible] = useState(() => {
    return scrollDelta.current >= 0 && isPortrait() && isSafari();
  });

  const listener = useCallback(() => {
    const position = document.documentElement.scrollTop;
    scrollDelta.current = position - scrollPosition.current;
    scrollPosition.current = position;
    setMenuVisible(scrollDelta.current >= 0 && isPortrait() && isSafari());
  }, []);

  useEffect(() => {
    if (isSafari()) {
      window.addEventListener('orientationchange', listener, { passive: true });
    }

    return () => {
      if (isSafari()) {
        window.removeEventListener('orientationchange', listener);
      }
    };
  }, [listener]);

  useEffect(() => {
    if (isSafari()) {
      window.addEventListener('scroll', listener, { passive: true });
    }

    return () => {
      if (isSafari()) {
        window.removeEventListener('scroll', listener);
      }
    };
  }, [listener]);

  return menuVisible;
}

export default useSafariMenuState;
