import {
  // CMS_COLLECTION_SERVICES,
  // CMS_FAQ_LIST,
  // CMS_FOOTER,
  SET_COCKPIT_THEME,
  CMS_THEME_LOAD,
} from '../_types';

const { REACT_APP_CMS_ENDPOINT, REACT_APP_CMS_KEY } = process.env;

export const getTheme = (x) => async (dispatch) => {
  const cms = {
    cmsCollectionServices: {},
    cmsFooter: {},
    cmsFaqList: {},
  };

  try {
    const language = x.split('/')[1];
    const subdomain = window.location.host.split('.')[1]
      ? window.location.host.split('.')[0]
      : 'shift';
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/BookerThemes?token=${REACT_APP_CMS_KEY}&filter[Domain]=${subdomain}&lang=${language}&populate=1`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get Theme');
    const spinnerRequest = await response.json();
    const spinnerResults = { ...spinnerRequest };

    if (spinnerResults.total) {
      const payload = {
        type: 'shiftOnlineTheme',
        ...spinnerResults.entries,
      };

      dispatch({
        type: SET_COCKPIT_THEME,
        payload,
      });

      let { RelatedFAQs, RelatedFooterMenu, RelatedServices } = payload['0'];

      if (RelatedFAQs === null) RelatedFAQs = [];
      if (RelatedFooterMenu === null) RelatedFooterMenu = [];
      if (RelatedServices === null) RelatedServices = [];

      if (RelatedFAQs) {
        cms.cmsFaqList = {
          entries: RelatedFAQs,
          total: RelatedFAQs.length,
        };
        // dispatch({
        //   type: CMS_FAQ_LIST,
        //   payload: {
        //     entries: RelatedFAQs,
        //     total: RelatedFAQs.length,
        //   },
        // });
      }

      if (RelatedFooterMenu) {
        cms.cmsFooter = {
          entries: RelatedFooterMenu,
          total: RelatedFooterMenu.length,
        };
        // dispatch({
        //   type: CMS_FOOTER,
        //   payload: {
        //     entries: RelatedFooterMenu,
        //     total: RelatedFooterMenu.length,
        //   },
        // });
      }

      if (RelatedServices) {
        cms.cmsCollectionServices = {
          entries: RelatedServices,
          total: RelatedServices.length,
        };
        // dispatch({
        //   type: CMS_COLLECTION_SERVICES,
        //   payload: {
        //     entries: RelatedServices,
        //     total: RelatedServices.length,
        //   },
        // });
      }

      dispatch({ type: CMS_THEME_LOAD, payload: cms });
      return;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
