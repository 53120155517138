import React, { Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';

const BlogComponentDetail = React.lazy(() => import('../BlogComponent/detail'));
const DynamicComponent = React.lazy(() => import('../DynamicComponent'));
const SeoComponent = React.lazy(() => import('../SeoComponent'));

const DynamicContentHandler = (props) => {
  const { routeSearch, theme } = props;
  const [allowRender, setAllowRender] = useState(false);

  useEffect(() => {
    if (
      theme.Domain !== 'shift' ||
      theme.Domain !== 'www' ||
      theme.Domain !== 'beta'
    ) {
      setAllowRender(true);
    }
  }, [theme]);

  if (routeSearch && routeSearch.type && allowRender) {
    if (routeSearch.type === 'shiftOnlineContent') {
      return (
        <Suspense fallback={<div />}>
          <DynamicComponent routeInfo={routeSearch['0']} />
        </Suspense>
      );
    }

    if (routeSearch.type === 'shiftOnlineContentSpinner') {
      return (
        <Suspense fallback={<div />}>
          <SeoComponent routeInfo={routeSearch['0']} />
        </Suspense>
      );
    }

    if (routeSearch.type === 'shiftOnlineBlog') {
      return (
        <Suspense fallback={<div />}>
          <BlogComponentDetail routeInfo={routeSearch['0']} />
        </Suspense>
      );
    }
  }
  return null;
};

const mapStateToProps = (state) => {
  const { routeSearch } = state.rootreducer.cms;
  const { theme } = state.config;
  return { routeSearch, theme };
};

export default connect(mapStateToProps, null)(DynamicContentHandler);
