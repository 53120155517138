/**
 * Returns the first segment of the current URI path
 *
 * @returns {string}
 */
function getRouteLanguage() {
  const segments = window.location.pathname.split(/\/+/);

  if (segments[1]) {
    return segments[1];
  }

  return 'gb-en'; // default fallback
}

export default getRouteLanguage;
