import {
    SET_COCKPIT_THEME,
} from '../_types';

const initialState = {
    theme: {},
};

const themeReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_COCKPIT_THEME: {
            return {
                ...state,
                theme: payload[0],
            };
        }
        default:
            return state;
    }
};

export default themeReducer;
