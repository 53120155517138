const formatAddress = (data) => {
  // format the data object to remove null values
  Object.keys(data).forEach((key) => {
    if (data[key] === null) {
      data[key] = '';
    }
  });

  const {
    Building: building,
    SubBuilding: subBuilding,
    Number: number,
    Thoroughfare: street,
    AdministrativeCounty: county,
    Postcode: postcode,
    Country: country,
    Latitude: latitude,
    Longitude: longitude,
    OrganisationName,
  } = data;

  const addressShortString = OrganisationName
    ? OrganisationName
    : subBuilding
    ? subBuilding
    : building
    ? building
    : `${number} ${street}`;

  const address = `${building} ${number} ${street} ${county} ${postcode} ${country}`;
  const longaddress = address.replace(addressShortString, '');

  return {
    address: longaddress,
    addressShort: addressShortString,
    postcode,
    latitude,
    longitude,
    response: data,
  };
};

export default formatAddress;
