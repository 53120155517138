import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getTheme } from '../../_actions/theme.action';
import { getTicket } from '../../_actions/bookerTicket.action';
import { loadCMS } from '../../_actions/cms.action';
import { smartlookInit } from '../../_actions/smartlook.action';

// Purely functional component to load data for redux and
// not cause a re-render for entire app
const DataLoader = (props) => {
  const {
    getTicket,
    smartlookInit,
    getTheme,
    loadCMS,
    location,
    history,
    theme,
  } = props;

  // On Routes Mount initialise smartLook
  useEffect(() => {
    smartlookInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check language and rebuild if incorrect
  useEffect(() => {
    const urlParts = location.pathname.split('/');
    const lang = urlParts[1];

    if (lang === 'gb-en') {
      urlParts[1] = 'en-gb';
      const path = urlParts.join('/');
      history.push(path);
    }
  }, [history, location]);

  useEffect(() => {
    // setLoading(true);
    const startTicketAsync = async () => {
      const fetchTicketData = async () => {
        let path = window.location.pathname;
        let splitPath = path.split('/');
        if (splitPath && splitPath.length && splitPath.length >= 4) {
          if (Object.keys(theme).length === 0) {
            await getTheme(path);
          }
          await getTicket(splitPath[3]);
        }
      };
      await fetchTicketData();
    };

    const startAsync = async () => {
      const fetchData = async () => {
        let path = window.location.pathname;
        loadCMS(path);
        if (Object.keys(theme).length === 0) {
          await getTheme(path);
        }
      };
      await fetchData();
    };

    if (!window.location.pathname.includes('booking')) {
      startAsync();
    } else {
      startTicketAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  // Always render null as component is for loading data only
  return null;
};

const mapStateToProps = (state) => {
  const { theme } = state.config;
  return { theme };
};

const mapDispatchToProps = {
  getTheme,
  getTicket,
  loadCMS,
  smartlookInit,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataLoader)
);
