//gap: (props) => theme[props.theme].primary
const styles = {
  root: {
    zIndex: 1,
    display: 'grid',
    gap: '1px',
    gridTemplateRows: 'auto 1fr auto',
    '@media only screen and (max-height: 568px) and (min-width: 1000px)': {
      gap: '0px',
    },
  },
  titleSubtitleDiv: {
    display: 'grid',
    marginTop: 40,
    gap: '20px',
    fontFamily: 'rawline',
    textAlign: 'center',
    marginBottom: 30,
    '@media only screen and (max-height: 568px) and (min-width: 1000px)': {
      marginTop: 5,
      marginBottom: 20,
      gap: '10px',
    },
    '& h1': {
      color: '#747c84',
      fontSize: 24,
      fontWeight: 500,
      margin: 0,
      letterSpacing: '0.9px',
      '@media only screen and (max-width: 320px)': {
        fontSize: 20,
      },
    },
  },
  detailsDiv: {
    display: 'grid',
    margin: '0 5px',
    gap: '20px',
  },
  description: {
    '& p': {
      fontSize: 18,
      margin: 0,
      '@media only screen and (max-width: 320px)': {
        fontSize: 16,
      },
    },
    '& ul': {
      paddingLeft: 20,
      listStyle: 'none',
      margin: 0,
      '& li': {
        fontWeight: 600,
        marginTop: 10,
        fontSize: 18,
        color: (state) => state.theme.PrimaryColour,
        '@media only screen and (max-width: 320px)': {
          fontSize: 16,
        },
        '&::before': {
          content: "'•'",
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },
      },
    },
  },
  phoneInput: {
    display: 'grid',
    gridTemplateColumns: '135px auto',
    gap: '10px',
    '@media only screen and (max-width: 320px)': {
      gridTemplateColumns: '125px auto',
    },
  },
  phoneCodeDiv: {
    display: 'grid',
    gridTemplateColumns: 'auto 60px',
    gap: '5px',
  },
  phoneCodeIcon: {
    display: 'grid',
    placeItems: 'center',
  },
  paddingFix: {
    padding: '20px 25px',
    '@media only screen and (max-width: 500px)': {
      padding: '10px',
    },
  },
  inputAdornment: {
    height: 'unset',
    color: (state) => state.theme.PrimaryColour,
  },
};

export default styles;
