import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux'

const useStyles = makeStyles({
  root: {
    border: '6px solid #f3f3f3a3',
    borderTop: (state) => (`6px solid ${state.theme.PrimaryColour}`),
    borderRadius: '50%',
    width: ({props}) => `calc(${props.width}px + 12px)`,
    height: ({props}) => `calc(${props.height}px + 12px)`,
    animation: '$spin 0.75s linear infinite',
    margin: 'auto',

  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    }
  },
});

const Spinner = (props) => {
  const {
    theme
  } = props
  
  const classes = useStyles({props, theme });
  
  return <div id={"spinnerRoot"} className={classes.root} />;
};


Spinner.defaultProps = {
  height: 100,
  width: 100,
};
const mapStateToProps = (state) => {
  const { theme } = state.config
  return { theme }
}


// Spinner.propTypes = {

// };

export default connect(mapStateToProps, null) (Spinner);