import React from 'react';

// Redux
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

// Components
import Routes from './_routes/Routes';
import CookieBanner from './_routes/CookieBanner';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          {/* <React.StrictMode> */}
          <div id='main-content-container'>
            <Routes />
          </div>
          <CookieBanner />
          {/* </React.StrictMode> */}
        </Router>
      </Provider>
    );
  }
}

export default App;
