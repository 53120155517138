const styles = {
  root: {
    display: 'grid',
    backgroundColor: (state) => state.theme.PrimaryColour,
    height: 65,
    left: 0,
    right: 0,
    zIndex: 1,
    '@media only screen and (max-width: 400px)': {
      height: 50,
    },
    position: 'relative',
  },
  logoDiv: {
    textAlign: 'center',
    placeSelf: 'center',
    '@media only screen and (max-width: 375px)': {
      height: '30px !important',
    },
    width: 180,
  },
  logo: {
    maxHeight: 52,
  },
  logo_clickable: {
    cursor: 'pointer',
  },
  curpicker: {
    cursor: 'pointer',
    position: 'absolute',
    right: 11,
    top: 11,
    background: '#ffffff29',
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 13,
    padding: '1px 8px 2px',
    borderRadius: 15,
    minWidth: 22,
  },
  backArrow: {
    cursor: 'pointer',
    position: 'absolute',
    width: '65px',
    height: '65px',
    color: 'white',
    padding: '15px',
    '@media only screen and (max-width: 375px)': {
      width: '50px',
      height: '50px',
      padding: '10px',
    },
    '&:hover': {
      transform: 'scale(1.05)',
      background: 'rgba(255, 255, 255, 0.05)',
    },
    '&:active': {
      transform: 'scale(1)',
    },
  },
  supportButton: {
    fontSize: '14px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: '0',
    height: '65px',
    color: (state) => state.theme.TertiaryTextColour,
    paddingLeft: '15px',
    paddingRight: '15px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.05)',
    },
    '@media only screen and (max-width: 460px)': {
      // display: 'none',
      height: '100%',
    },
    '& svg': {
      fill: ({ theme }) => theme.ChatIconColour,
    },
  },
};

export default styles;
