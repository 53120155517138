import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import CapturePhonePopup from '../CapturePhonePopup';
import {
  showPopup,
  setPopup,
  hideCloseButton,
} from '../../../../_actions/popup.action';
import { fetchCapturePhone } from '../../../../_actions/capturePhone.action';

const CapturePhonePopupHandler = (props) => {
  const {
    capturePhone,
    capturePhoneShowClose,
    hideCloseButton,
    fetchCapturePhone,
    showPopup,
    setPopup,
    ticket,
  } = props;

  useEffect(() => {
    fetchCapturePhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (capturePhone === false) {
      return;
    }

    const isDeliverySlot = /\/delivery-slot$/i.test(window.location.pathname);

    if (ticket && ticket.id && isDeliverySlot) {
      if (isEmpty(ticket.phone) && isEmpty(ticket.customerId)) {
        if (capturePhoneShowClose === false) {
          hideCloseButton();
        }
        setPopup(<CapturePhonePopup />);
        showPopup();
      }
    }
  }, [
    ticket,
    capturePhone,
    capturePhoneShowClose,
    hideCloseButton,
    showPopup,
    setPopup,
  ]);

  return null;
};

const mapStateToProps = (state) => {
  const { ticket } = state.rootreducer;
  const capturePhone = state.capturePhone.shouldCapture;
  const capturePhoneShowClose = state.capturePhone.closeWindow;
  return { capturePhone, capturePhoneShowClose, ticket };
};

const mapDispatchToProps = {
  setPopup,
  showPopup,
  hideCloseButton,
  fetchCapturePhone,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CapturePhonePopupHandler);
