import { GET_PRICE_PLANS, REPLACE_PRICE_PLANS } from '../_types';

const initialState = [];

const pricePlansReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch(type) {
    case GET_PRICE_PLANS: {
      return [...state, ...payload];
    }

    case REPLACE_PRICE_PLANS: {
      return [...payload];
    }

    default: return state;
  }
};

export default pricePlansReducer;