import { ISERROR, ISLOADING, INVENTORYLISTAPI } from '../_types';

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
};

const inventoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ISERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case ISLOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case INVENTORYLISTAPI:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

const JSONdata = [
  {
    Name: 'Furniture & Appliances',
    Icon: 'furniture.png',
    frontendName: 'Furniture Removals',
    frontendDescription:
      'Moving or delivering furniture shouldn’t mean you need to sit down, just use Shift instead...',
    Type: 'search',
    Cats: '13,14,16,1,9',
    childTitle: 'Furniture & Appliances',
  },
  {
    Name: 'Moving Home',
    Icon: 'house.png',
    frontendName: 'House Removals',
    frontendDescription:
      'Moving home doesn’t have to be an upheaval, just use Shift instead...',
    Type: 'list',
    childTitle: 'Moving Home',
    childItems: [
      {
        Name: 'Small / Student Move',
        Description: 'Boxes and Bags no furniture',
        Icon: 'student.svg',
        InventoryId: '9492472126',
      },
      {
        Name: 'Small Move',
        Description: '1 to 2 rooms of standard items',
        Icon: 'small.svg',
        InventoryId: '0861331891',
      },
      {
        Name: 'Medium Move',
        Description: '2 to 3 rooms of standard items',
        Icon: 'mid.svg',
        InventoryId: '2885613868',
      },
      {
        Name: 'Large Home',
        Description: '3 to 4 rooms maximum of standard items',
        Icon: 'large.svg',
        InventoryId: '3862697030',
      },
    ],
  },
  {
    Name: 'Packaged Items & Parcels',
    Icon: 'van.png',
    frontendName: 'Man & Van Services',
    frontendDescription:
      'If you’re looking for a man and a van, Shift is just what you need...',
    Type: 'search',
    Cats: '13,14,16,1,9',
    childTitle: 'Packaged Items & Parcels',
  },
  {
    Name: 'Motorbikes',
    Icon: 'motorbike.png',
    frontendName: 'Motorbike Delivery',
    frontendDescription:
      "Delivering a motorcycle shouldn't need a crash helmet, just use Shift instead...",
    Type: 'search',
    Cats: '13,14,16,1,9',
    childTitle: 'Motorbikes',
  },
  {
    Name: 'Office',
    Icon: 'office.png',
    frontendName: 'Office Removal',
    frontendDescription:
      'Office relocation doesn’t have to be a drama, just use Shift instead...',
    Type: 'search',
    Cats: '13,14,16,1,9',
    childTitle: 'Office',
  },
  {
    Name: 'Other',
    Icon: 'sofa.png',
    frontendName: 'Sofa Removal',
    frontendDescription:
      'Moving a sofa shouldn’t mean you need to have a lie down, just use Shift instead...',
    Type: 'search',
    Cats: '13,14,16,1,9',
    childTitle: 'Other',
  },
  {
    Name: 'Piano',
    Icon: 'piano.png',
    frontendName: 'Piano Removal',
    frontendDescription:
      'Moving a piano doesn’t need an orchestra, just use Shift instead...',
    Type: 'search',
    Cats: '13,14,16,1,9',
    childTitle: 'Piano',
  },
  {
    Name: 'Rubbish',
    Icon: 'rubbish.png',
    frontendName: 'Waste Disposal',
    frontendDescription:
      'Rubbish removal doesn’t need to cause a stink, just use Shift instead...',
    Type: 'search',
    Cats: '13,14,16,1,9',
    childTitle: 'Rubbish',
  },
];

const caseStudiesReducer = (state = JSONdata, action) => {
  return state;
};

export { inventoryListReducer, caseStudiesReducer };
