import { useEffect, useState } from 'react';

const observer = window.matchMedia('screen and (max-width: 500px)');

/**
 * Returns true if the app is running in a viewport with a width <= 500px, and
 * the client device supports touch points.
 *
 * @deprecated see the useMobileDevice hook instead
 */
function useIsMobile() {
  const [state, setState] = useState(
    observer.matches && window.navigator.maxTouchPoints > 0
  );

  useEffect(() => {
    const listener = () => {
      setState(observer.matches && window.navigator.maxTouchPoints > 0);
    };

    observer.addListener(listener);

    return () => {
      observer.removeListener(listener);
    };
  }, []);

  return state;
}

export default useIsMobile;
