import { GET_BOOKER_TICKET, GET_TICKET } from '../_types';

const { REACT_APP_ENDPOINT } = process.env;

export const createTicket = (payload, callback) => async (dispatch) => {
  try {
    const address = `${REACT_APP_ENDPOINT}/shiftonline/ticket/create`;
    const request = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const response = await fetch(address, request);
    if (response.status !== 200) throw new Error('Failed to create ticket.');
    const newTicket = await response.json();

    updateTicket(newTicket.id, payload, false, callback)(dispatch);

    // dispatch({
    //   type: GET_BOOKER_TICKET,
    //   payload: ticket,
    // });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getTicket = (id) => async (dispatch) => {
  try {
    const response = await fetch(`${REACT_APP_ENDPOINT}/ticket/get/${id}`);
    if (response.status !== 200) throw new Error('Failed to get ticket.');
    const ticket = await response.json();
    if (ticket === 'Ticket not found') {
      window.location.href = 'https://try.shift.online/home';
    }
    const payload = { ...ticket };
    dispatch({
      type: GET_BOOKER_TICKET,
      payload,
    });

    dispatch({
      type: GET_TICKET,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const updateTicket = (id, changes, updatePrice, callback) => async (
  dispatch
) => {
  try {
    let url = `${REACT_APP_ENDPOINT}/shiftonline/ticket/update`;
    url += updatePrice ? '?updatePrice=1' : '';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ id, ...changes }),
    });

    if (response.status !== 200) throw new Error('Failed to update ticket.');
    const ticket = await response.json();
    const payload = { ...ticket };
    dispatch({
      type: GET_BOOKER_TICKET,
      payload,
    });
    if (callback) {
      callback(payload.id);
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const validateShopping = (ticket_id) => async (dispatch) => {
  try {
    const url = `${REACT_APP_ENDPOINT}/shopping/valid`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ticket_id, valid: 1 }),
    });

    if (response.status !== 200) throw new Error('Failed to update ticket.');
    dispatch(getTicket(ticket_id));
  } catch (error) {
    console.error(error);
    return error;
  }
};
