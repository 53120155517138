import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import HelpComment from '../../assets/svg/HelpComment.svg.js';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useState, useLayoutEffect } from 'react';
import styles from './styles';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(styles);

const FOR_MOBILE = '(max-width: 580px)';

const Header = (props) => {
  const { theme, history, headerAction } = props;
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const query = window.matchMedia(FOR_MOBILE);
    const listener = () => {
      setIsMobile(query.matches);
    };

    setIsMobile(query.matches);

    query.addListener(listener);

    return () => {
      query.removeListener(listener);
    };
  }, []);

  const handleClickLogo = () => {
    const segments = window.location.pathname.split('/');
    window.location.pathname = '/' + segments[1] + '/';
  };

  const classes = useStyles({ theme });

  const processHistoryButton = () => {
    history.push(headerAction.link);
  };

  const handleSupportButtonClick = useCallback(() => {
    try {
      window.Intercom('show');
    } catch {
      console.error('Failed to launch the intercom from the header');
    }
  }, []);

  return (
    <div id={'HeaderRoot'} className={[classes.root, 'theme_header'].join(' ')}>
      {headerAction ? (
        headerAction.link ? (
          <ChevronLeftIcon
            onClick={processHistoryButton}
            className={classes.backArrow}
          ></ChevronLeftIcon>
        ) : null
      ) : null}

      <div
        className={classes.logoDiv}
        style={
          isMobile
            ? {
                width: `${
                  theme.mobileLogoWidth ? theme.mobileLogoWidth : 80
                }px`,
              }
            : null
        }
      >
        {theme.Logo && (
          <img
            className={[
              classes.logo,
              theme.LogoRedirect ? classes.logo_clickable : null,
            ].join(' ')}
            onClick={theme.LogoRedirect ? handleClickLogo : null}
            src={`${'https://shift.online'}${theme.Logo.path}`}
            alt=''
          />
        )}
      </div>

      {true ? (
        <div
          id='header-support-button'
          className={classes.supportButton}
          onClick={handleSupportButtonClick}
        >
          {isMobile ? <HelpComment /> : 'Contact Support'}
        </div>
      ) : null}
    </div>
  );
};

Header.propTypes = {};

const mapStateToProps = (state) => {
  const { theme } = state.config;
  const { headerAction } = state.summaryReducer;
  return { theme, headerAction };
};
const mapDispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
