import {
  INITIALISE_SHIFT_SHOP_CART,
  SET_SHIFT_SHOP_CART,
  SET_DELIVERY_SLOTS,
  SET_SELECTED_DELIVERY_SLOT_ID,
  SET_SELECTED_DELVIERY_DATE,
  SET_STORES,
  SET_SELECTED_STORE,
} from '../_types';

const initialState = {
  cartItems: [],
  deliverySlots: [],
  selectedDeliverySlotId: '',
  selectedDeliveryDate: 0,
  selectedStore: {},
  stores: [],
};

const shiftShopReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INITIALISE_SHIFT_SHOP_CART:
    case SET_SHIFT_SHOP_CART: {
      return { ...state, cartItems: payload };
    }

    case SET_DELIVERY_SLOTS: {
      return {
        ...state,
        deliverySlots: payload,
      };
    }

    case SET_SELECTED_DELIVERY_SLOT_ID: {
      return {
        ...state,
        selectedDeliverySlotId: payload,
      };
    }

    case SET_SELECTED_DELVIERY_DATE: {
      return {
        ...state,
        selectedDeliveryDate: payload,
      };
    }

    case SET_STORES: {
      return {
        ...state,
        stores: payload,
      };
    }

    case SET_SELECTED_STORE: {
      return {
        ...state,
        selectedStore: payload,
      };
    }

    default:
      return state;
  }
};

export default shiftShopReducer;
