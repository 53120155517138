import {
  COOKIE_BANNER_SET_ACCEPTED,
  COOKIE_BANNER_SET_HEIGHT,
} from '../_types/cookieBanner.type';

const STORAGE_KEY = 'cookies-accepted';
const STORAGE_VALUE = 'yes';

let storage;

if (process.env.NODE_ENV === 'development') {
  storage = window.sessionStorage;
} else {
  storage = window.localStorage;
}

const initialState = {
  height: 0,
  visible: storage.getItem(STORAGE_KEY) !== STORAGE_VALUE,
};

const cookieBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case COOKIE_BANNER_SET_ACCEPTED: {
      storage.setItem(STORAGE_KEY, STORAGE_VALUE);
      return {
        ...state,
        visible: false,
      };
    }
    case COOKIE_BANNER_SET_HEIGHT: {
      return {
        ...state,
        height: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default cookieBannerReducer;
