import {
  CMS_HEADER,
  CMS_COLLECTION_SERVICES,
  CMS_FOOTER,
  CMS_FOOTERCOPYRIGHT,
  CMS_BOOKER_BANNER,
  CMS_CASE_STUDIES,
  CMS_CONTENT,
  CMS_ROUTE_SEARCH,
  CMS_WEBSITE_MENU,
  CMS_FAQ_LIST,
  CMS_ROUTES,
  CMS_CONTENT_SPINNER,
  CMS_POPUPS,
  CMS_LOAD,
  CMS_THEME_LOAD,
} from '../_types';
/*
const initialState = {
  cmsHeader: {},
  cmsCollectionServices: {},
  cmsFooter: {},
  cmsFooterCopyright: {},
  cmsCaseStudies: {},
  cmsContent: {},
  cmsContentSpinner: {},
  cmsWebsiteMenu: {},
  cmsFaqList: {},
  cmsRoutes: {},
  cmsPopups: {},
  routeSearch: {},
};
*/
const cmsReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CMS_HEADER: {
      return {
        ...state,
        cmsHeader: payload,
      };
    }

    case CMS_COLLECTION_SERVICES: {
      return {
        ...state,
        cmsCollectionServices: payload,
      };
    }

    case CMS_FAQ_LIST: {
      return {
        ...state,
        cmsFaqList: payload,
      };
    }

    case CMS_FOOTERCOPYRIGHT: {
      return {
        ...state,
        cmsFooterCopyright: payload,
      };
    }
    case CMS_FOOTER: {
      return {
        ...state,
        cmsFooter: payload,
      };
    }

    case CMS_BOOKER_BANNER: {
      return {
        ...state,
        cmsBookerBanner: payload,
      };
    }

    case CMS_CASE_STUDIES: {
      return {
        ...state,
        cmsCaseStudies: payload,
      };
    }

    case CMS_CONTENT: {
      return {
        ...state,
        cmsContent: payload,
      };
    }

    case CMS_ROUTE_SEARCH: {
      return {
        ...state,
        routeSearch: payload,
      };
    }

    case CMS_CONTENT_SPINNER: {
      return {
        ...state,
        cmsContentSpinner: payload,
      };
    }

    case CMS_WEBSITE_MENU: {
      return {
        ...state,
        cmsWebsiteMenu: payload,
      };
    }

    case CMS_ROUTES: {
      return {
        ...state,
        cmsRoutes: payload,
      };
    }

    case CMS_POPUPS: {
      return {
        ...state,
        cmsPopups: payload,
      };
    }

    case CMS_LOAD: {
      return { ...state, ...payload };
    }

    case CMS_THEME_LOAD: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};

export default cmsReducer;
