import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import useScrollPosition from '@react-hook/window-scroll';
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/fontawesome-free-solid';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: (state) => {
      if (state.hidden) {
        return '-100px';
      }

      if (state.cookieBannerVisible) {
        return 24 + state.cookieBannerHeight + 'px';
      }

      return '24px';
    },
    right: 24,
    cursor: 'pointer',
    height: 50,
    width: 50,
    color: 'white',
    backgroundColor: (state) => state.theme.PrimaryColour,
    borderRadius: 25,
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    '@media only screen and (max-width: 500px)': {
      fontSize: 13,
      height: 23,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '5px',
    zIndex: 10000,
    transition: 'bottom 0.3s ease-in-out',
  },
});

const ScrollToTop = (props) => {
  const { theme, cookieBannerHeight, cookieBannerVisible } = props;
  //Returns the scroll Y position at a rate of 30 fps
  const scrollY = useScrollPosition(30);
  const [hidden, setHidden] = useState(true);
  const scroll = Scroll.animateScroll;

  const classes = useStyles({
    theme,
    hidden,
    cookieBannerHeight,
    cookieBannerVisible,
  });

  if (hidden && scrollY >= 100) {
    setHidden(false);
  } else if (!hidden && scrollY < 100) {
    setHidden(true);
  }

  return (
    <div
      id={'scrollToTopRoot'}
      className={classes.root}
      onClick={() => scroll.scrollToTop()}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.config.theme,
    cookieBannerHeight: state.cookieBanner.height,
    cookieBannerVisible: state.cookieBanner.visible,
  };
};

export default connect(mapStateToProps)(ScrollToTop);
