const countries = [
  { id: 'GB', label: 'United Kingdom', value: '+44', locale: 'gb-en' },
  { id: 'US', label: 'United States', value: '+1', locale: 'us-en' },
  { id: 'AF', label: 'Afghanistan', value: '+93', locale: '' },
  { id: 'AL', label: 'Albania', value: '+355', locale: '' },
  { id: 'DZ', label: 'Algeria', value: '+213', locale: '' },
  { id: 'AS', label: 'American Samoa', value: '+1684', locale: '' },
  { id: 'AD', label: 'Andorra', value: '+376', locale: '' },
  { id: 'AO', label: 'Angola', value: '+244', locale: '' },
  { id: 'AI', label: 'Anguilla', value: '+1264', locale: '' },
  { id: 'AG', label: 'Antigua and Barbuda', value: '+1268', locale: '' },
  { id: 'AR', label: 'Argentina', value: '+54', locale: '' },
  { id: 'AM', label: 'Armenia', value: '+374', locale: '' },
  { id: 'AW', label: 'Aruba', value: '+297', locale: '' },
  { id: 'AU', label: 'Australia', value: '+61', locale: '' },
  { id: 'AT', label: 'Austria', value: '+43', locale: '' },
  { id: 'AZ', label: 'Azerbaijan', value: '+994', locale: '' },
  { id: 'BS', label: 'Bahamas', value: '+1242', locale: '' },
  { id: 'BD', label: 'Bangladesh', value: '+880', locale: '' },
  { id: 'BB', label: 'Barbados', value: '+1246', locale: '' },
  { id: 'BY', label: 'Belarus', value: '+375', locale: '' },
  { id: 'BE', label: 'Belgium', value: '+32', locale: '' },
  { id: 'BZ', label: 'Belize', value: '+501', locale: '' },
  { id: 'BJ', label: 'Benin', value: '+229', locale: '' },
  { id: 'BM', label: 'Bermuda', value: '+1441', locale: '' },
  { id: 'BT', label: 'Bhutan', value: '+975', locale: '' },
  {
    id: 'BO',
    label: 'Bolivia, Plurinational State of',
    value: '+591',
    locale: '',
  },
  { id: 'BA', label: 'Bosnia and Herzegovina', value: '+387', locale: '' },
  { id: 'BW', label: 'Botswana', value: '+267', locale: '' },
  { id: 'BR', label: 'Brazil', value: '+55', locale: '' },
  {
    id: 'IO',
    label: 'British Indian Ocean Territory',
    value: '+246',
    locale: '',
  },
  { id: 'BG', label: 'Bulgaria', value: '+359', locale: '' },
  { id: 'BF', label: 'Burkina Faso', value: '+226', locale: '' },
  { id: 'BI', label: 'Burundi', value: '+257', locale: '' },
  { id: 'KH', label: 'Cambodia', value: '+855', locale: '' },
  { id: 'CM', label: 'Cameroon', value: '+237', locale: '' },
  { id: 'CA', label: 'Canada', value: '+1', locale: '' },
  { id: 'CV', label: 'Cape Verde', value: '+238', locale: '' },
  { id: 'KY', label: 'Cayman Islands', value: '+1345', locale: '' },
  { id: 'CF', label: 'Central African Republic', value: '+236', locale: '' },
  { id: 'TD', label: 'Chad', value: '+235', locale: '' },
  { id: 'CL', label: 'Chile', value: '+56', locale: '' },
  { id: 'CN', label: 'China', value: '+86', locale: '' },
  { id: 'CO', label: 'Colombia', value: '+57', locale: '' },
  { id: 'KM', label: 'Comoros', value: '+269', locale: '' },
  { id: 'CG', label: 'Congo', value: '+242', locale: '' },
  {
    id: 'CD',
    label: 'Democratic Republic of the Congo',
    value: '+243',
    locale: '',
  },
  { id: 'CK', label: 'Cook Islands', value: '+682', locale: '' },
  { id: 'CR', label: 'Costa Rica', value: '+506', locale: '' },
  { id: 'CI', label: "Côte d'Ivoire", value: '+225', locale: '' },
  { id: 'HR', label: 'Croatia', value: '+385', locale: '' },
  { id: 'CU', label: 'Cuba', value: '+53', locale: '' },
  { id: 'CW', label: 'Curaçao', value: '+599', locale: '' },
  { id: 'CY', label: 'Cyprus', value: '+357', locale: '' },
  { id: 'CZ', label: 'Czech Republic', value: '+420', locale: '' },
  { id: 'DK', label: 'Denmark', value: '+45', locale: '' },
  { id: 'DJ', label: 'Djibouti', value: '+253', locale: '' },
  { id: 'DM', label: 'Dominica', value: '+1767', locale: '' },
  { id: 'DO', label: 'Dominican Republic', value: '+1809', locale: '' },
  { id: 'EC', label: 'Ecuador', value: '+593', locale: '' },
  { id: 'EG', label: 'Egypt', value: '+20', locale: '' },
  { id: 'SV', label: 'El Salvador', value: '+503', locale: '' },
  { id: 'GQ', label: 'Equatorial Guinea', value: '+240', locale: '' },
  { id: 'ER', label: 'Eritrea', value: '+291', locale: '' },
  { id: 'EE', label: 'Estonia', value: '+372', locale: '' },
  { id: 'ET', label: 'Ethiopia', value: '+251', locale: '' },
  { id: 'FK', label: 'Falkland Islands (Malvinas)', value: '+500', locale: '' },
  { id: 'FO', label: 'Faroe Islands', value: '+298', locale: '' },
  { id: 'FJ', label: 'Fiji', value: '+679', locale: '' },
  { id: 'FI', label: 'Finland', value: '+358', locale: '' },
  { id: 'FR', label: 'France', value: '+33', locale: '' },
  { id: 'PF', label: 'French Polynesia', value: '+689', locale: '' },
  { id: 'GA', label: 'Gabon', value: '+241', locale: '' },
  { id: 'GM', label: 'Gambia', value: '+220', locale: '' },
  { id: 'GE', label: 'Georgia', value: '+995', locale: '' },
  { id: 'DE', label: 'Germany', value: '+49', locale: '' },
  { id: 'GH', label: 'Ghana', value: '+233', locale: '' },
  { id: 'GI', label: 'Gibraltar', value: '+350', locale: '' },
  { id: 'GR', label: 'Greece', value: '+30', locale: '' },
  { id: 'GL', label: 'Greenland', value: '+299', locale: '' },
  { id: 'GD', label: 'Grenada', value: '+1473', locale: '' },
  { id: 'GU', label: 'Guam', value: '+1671', locale: '' },
  { id: 'GT', label: 'Guatemala', value: '+502', locale: '' },
  { id: 'GG', label: 'Guernsey', value: '+44', locale: '' },
  { id: 'GN', label: 'Guinea', value: '+224', locale: '' },
  { id: 'GW', label: 'Guinea-Bissau', value: '+245', locale: '' },
  { id: 'HT', label: 'Haiti', value: '+509', locale: '' },
  { id: 'HN', label: 'Honduras', value: '+504', locale: '' },
  { id: 'HK', label: 'Hong Kong', value: '+852', locale: '' },
  { id: 'HU', label: 'Hungary', value: '+36', locale: '' },
  { id: 'IS', label: 'Iceland', value: '+354', locale: '' },
  { id: 'IN', label: 'India', value: '+91', locale: '' },
  { id: 'ID', label: 'Indonesia', value: '+62', locale: '' },
  { id: 'IR', label: 'Iran, Islamic Republic of', value: '+98', locale: '' },
  { id: 'IQ', label: 'Iraq', value: '+964', locale: '' },
  { id: 'IE', label: 'Ireland', value: '+353', locale: '' },
  { id: 'IM', label: 'Isle of Man', value: '+44', locale: '' },
  { id: 'IL', label: 'Israel', value: '+972', locale: '' },
  { id: 'IT', label: 'Italy', value: '+39', locale: '' },
  { id: 'JM', label: 'Jamaica', value: '+1876', locale: '' },
  { id: 'JP', label: 'Japan', value: '+81', locale: '' },
  { id: 'JE', label: 'Jersey', value: '+44', locale: '' },
  { id: 'JO', label: 'Jordan', value: '+962', locale: '' },
  { id: 'KZ', label: 'Kazakhstan', value: '+7', locale: '' },
  { id: 'KE', label: 'Kenya', value: '+254', locale: '' },
  { id: 'KI', label: 'Kiribati', value: '+686', locale: '' },
  { id: 'KP', label: 'North Korea', value: '+850', locale: '' },
  { id: 'KR', label: 'South Korea', value: '+82', locale: '' },
  { id: 'KW', label: 'Kuwait', value: '+965', locale: '' },
  { id: 'KG', label: 'Kyrgyzstan', value: '+996', locale: '' },
  {
    id: 'LA',
    label: "Lao People's Democratic Republic",
    value: '+856',
    locale: '',
  },
  { id: 'LV', label: 'Latvia', value: '+371', locale: '' },
  { id: 'LB', label: 'Lebanon', value: '+961', locale: '' },
  { id: 'LS', label: 'Lesotho', value: '+266', locale: '' },
  { id: 'LR', label: 'Liberia', value: '+231', locale: '' },
  { id: 'LY', label: 'Libya', value: '+218', locale: '' },
  { id: 'LI', label: 'Liechtenstein', value: '+423', locale: '' },
  { id: 'LT', label: 'Lithuania', value: '+370', locale: '' },
  { id: 'LU', label: 'Luxembourg', value: '+352', locale: '' },
  { id: 'MO', label: 'Macau', value: '+853', locale: '' },
  { id: 'MK', label: 'Republic of Macedonia', value: '+389', locale: '' },
  { id: 'MG', label: 'Madagascar', value: '+261', locale: '' },
  { id: 'MW', label: 'Malawi', value: '+265', locale: '' },
  { id: 'MY', label: 'Malaysia', value: '+60', locale: '' },
  { id: 'MV', label: 'Maldives', value: '+960', locale: '' },
  { id: 'ML', label: 'Mali', value: '+223', locale: '' },
  { id: 'MT', label: 'Malta', value: '+356', locale: '' },
  { id: 'MH', label: 'Marshall Islands', value: '+692', locale: '' },
  { id: 'MQ', label: 'Martinique', value: '+596', locale: '' },
  { id: 'MR', label: 'Mauritania', value: '+222', locale: '' },
  { id: 'MU', label: 'Mauritius', value: '+230', locale: '' },
  { id: 'MX', label: 'Mexico', value: '+52', locale: '' },
  {
    id: 'FM',
    label: 'Micronesia, Federated States of',
    value: '+691',
    locale: '',
  },
  { id: 'MD', label: 'Republic of Moldova', value: '+373', locale: '' },
  { id: 'MC', label: 'Monaco', value: '+377', locale: '' },
  { id: 'MN', label: 'Mongolia', value: '+976', locale: '' },
  { id: 'ME', label: 'Montenegro', value: '+382', locale: '' },
  { id: 'MS', label: 'Montserrat', value: '+1664', locale: '' },
  { id: 'MA', label: 'Morocco', value: '+212', locale: '' },
  { id: 'MZ', label: 'Mozambique', value: '+258', locale: '' },
  { id: 'MM', label: 'Myanmar', value: '+95', locale: '' },
  { id: 'NA', label: 'Namibia', value: '+264', locale: '' },
  { id: 'NR', label: 'Nauru', value: '+674', locale: '' },
  { id: 'NP', label: 'Nepal', value: '+977', locale: '' },
  { id: 'NL', label: 'Netherlands', value: '+31', locale: '' },
  { id: 'NZ', label: 'New Zealand', value: '+64', locale: '' },
  { id: 'NI', label: 'Nicaragua', value: '+505', locale: '' },
  { id: 'NE', label: 'Niger', value: '+227', locale: '' },
  { id: 'NG', label: 'Nigeria', value: '+234', locale: '' },
  { id: 'NU', label: 'Niue', value: '+683', locale: '' },
  { id: 'NF', label: 'Norfolk Island', value: '+672', locale: '' },
  { id: 'MP', label: 'Northern Mariana Islands', value: '+670', locale: '' },
  { id: 'NO', label: 'Norway', value: '+47', locale: '' },
  { id: 'OM', label: 'Oman', value: '+968', locale: '' },
  { id: 'PK', label: 'Pakistan', value: '+92', locale: '' },
  { id: 'PW', label: 'Palau', value: '+680', locale: '' },
  { id: 'PS', label: 'Palestinian Territory', value: '+970', locale: '' },
  { id: 'PA', label: 'Panama', value: '+507', locale: '' },
  { id: 'PG', label: 'Papua New Guinea', value: '+675', locale: '' },
  { id: 'PY', label: 'Paraguay', value: '+595', locale: '' },
  { id: 'PE', label: 'Peru', value: '+51', locale: '' },
  { id: 'PH', label: 'Philippines', value: '+63', locale: '' },
  { id: 'PN', label: 'Pitcairn', value: '+64', locale: '' },
  { id: 'PL', label: 'Poland', value: '+48', locale: '' },
  { id: 'PT', label: 'Portugal', value: '+351', locale: '' },
  { id: 'PR', label: 'Puerto Rico', value: '+1787', locale: '' },
  { id: 'QA', label: 'Qatar', value: '+974', locale: '' },
  { id: 'RO', label: 'Romania', value: '+40', locale: '' },
  { id: 'RU', label: 'Russian', value: '+7', locale: '' },
  { id: 'RW', label: 'Rwanda', value: '+250', locale: '' },
  { id: 'KN', label: 'Saint Kitts and Nevis', value: '+1869', locale: '' },
  { id: 'WS', label: 'Samoa', value: '+685', locale: '' },
  { id: 'SM', label: 'San Marino', value: '+378', locale: '' },
  { id: 'ST', label: 'Sao Tome and Principe', value: '+239', locale: '' },
  { id: 'SA', label: 'Saudi Arabia', value: '+966', locale: '' },
  { id: 'SN', label: 'Senegal', value: '+221', locale: '' },
  { id: 'RS', label: 'Serbia', value: '+381', locale: '' },
  { id: 'SC', label: 'Seychelles', value: '+248', locale: '' },
  { id: 'SL', label: 'Sierra Leone', value: '+232', locale: '' },
  { id: 'SG', label: 'Singapore', value: '+65', locale: '' },
  { id: 'SX', label: 'Sint Maarten', value: '+599', locale: '' },
  { id: 'SK', label: 'Slovakia', value: '+421', locale: '' },
  { id: 'SI', label: 'Slovenia', value: '+386', locale: '' },
  { id: 'SB', label: 'Solomon Islands', value: '+677', locale: '' },
  { id: 'SO', label: 'Somalia', value: '+252', locale: '' },
  { id: 'ZA', label: 'South Africa', value: '+27', locale: '' },
  { id: 'SS', label: 'South Sudan', value: '+211', locale: '' },
  { id: 'ES', label: 'Spain', value: '+34', locale: '' },
  { id: 'LK', label: 'Sri Lanka', value: '+94', locale: '' },
  { id: 'SD', label: 'Sudan', value: '+249', locale: '' },
  { id: 'SR', label: 'Suriname', value: '+597', locale: '' },
  { id: 'SZ', label: 'Swaziland', value: '+268', locale: '' },
  { id: 'SE', label: 'Sweden', value: '+46', locale: '' },
  { id: 'CH', label: 'Switzerland', value: '+41', locale: '' },
  { id: 'SY', label: 'Syria', value: '+963', locale: '' },
  { id: 'TW', label: 'Taiwan, Province of China', value: '+886', locale: '' },
  { id: 'TJ', label: 'Tajikistan', value: '+992', locale: '' },
  { id: 'TZ', label: 'Tanzania', value: '+255', locale: '' },
  { id: 'TH', label: 'Thailand', value: '+66', locale: '' },
  { id: 'TG', label: 'Togo', value: '+228', locale: '' },
  { id: 'TK', label: 'Tokelau', value: '+690', locale: '' },
  { id: 'TO', label: 'Tonga', value: '+676', locale: '' },
  { id: 'TT', label: 'Trinidad and Tobago', value: '+1868', locale: '' },
  { id: 'TN', label: 'Tunisia', value: '+216', locale: '' },
  { id: 'TR', label: 'Turkey', value: '+90', locale: '' },
  { id: 'TM', label: 'Turkmenistan', value: '+993', locale: '' },
  { id: 'TC', label: 'Turks and Caicos Islands', value: '+1649', locale: '' },
  { id: 'TV', label: 'Tuvalu', value: '+688', locale: '' },
  { id: 'UG', label: 'Uganda', value: '+256', locale: '' },
  { id: 'UA', label: 'Ukraine', value: '+380', locale: '' },
  { id: 'AE', label: 'United Arab Emirates', value: '+971', locale: '' },
  { id: 'UY', label: 'Uruguay', value: '+598', locale: '' },
  { id: 'UZ', label: 'Uzbekistan', value: '+998', locale: '' },
  { id: 'VU', label: 'Vanuatu', value: '+678', locale: '' },
  {
    id: 'VE',
    label: 'Venezuela, Bolivarian Republic of',
    value: '+58',
    locale: '',
  },
  { id: 'VN', label: 'Viet Nam', value: '+84', locale: '' },
  { id: 'VI', label: 'Virgin Islands', value: '+1', locale: '' },
  { id: 'YE', label: 'Yemen', value: '+967', locale: '' },
  { id: 'ZM', label: 'Zambia', value: '+260', locale: '' },
  { id: 'ZW', label: 'Zimbabwe', value: '+263', locale: '' },
];

export default countries;
