import {
  COOKIE_BANNER_SET_ACCEPTED,
  COOKIE_BANNER_SET_HEIGHT,
} from '../_types/cookieBanner.type';

export const setCookiesAccepted = () => (dispatch) => {
  dispatch({
    type: COOKIE_BANNER_SET_ACCEPTED,
  });
};

export const setCookieBannerHeight = (height) => (dispatch) => {
  dispatch({
    type: COOKIE_BANNER_SET_HEIGHT,
    payload: height,
  });
};
