const styles = {
  rootField: {
    color: '#A0A0A0 !important',
    fontFamily: 'rawline, arial, sans-seif',
    fontSize: '1rem',
    lineHeight: '1.2rem',
  },
  rootFieldBorder: {
    border: '1px solid #ececec !important',
    borderRadius: '8px !important',
  },
  menuText: {
    '& li': {
      fontFamily: 'rawline, arial, sans-seif',
      justifyContent: 'unset !important',
    },
  },
  inputAdornment: {
    height: 'unset',
    color: '#d71a64',
  },
  errorTxt: {
    color: 'rgb(240, 0, 0)',
    top: '42px',
    left: '-8px',
    padding: '2px 8px',
    border: '1px solid rgb(220, 220, 220)',
    borderRadius: '4px',
    position: 'absolute',
    backgroundColor: 'rgb(255, 255, 255)',
    lineHeight: '1',
  },
  root: {
    width: '100%',
  },
  focused: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ececec',
      },
      '&:hover fieldset': {
        borderColor: '#ececec',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ececec',
      },
    },
  },

  errorAnimation: {
    animation: `$shakeError 200ms`,
  },

  '@keyframes shakeError': {
    '0%': {
      transform: 'translateX(0%)',
    },
    '25%': {
      transform: 'translateX(-5%)',
    },
    '75%': {
      transform: 'translateX(+5%)',
    },
    '100%': {
      transform: 'translateX(0%)',
    },
  },
};

export default styles;
