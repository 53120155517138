import {
  CMS_HEADER,
  CMS_FOOTER,
  CMS_FOOTERCOPYRIGHT,
  CMS_COLLECTION_SERVICES,
  CMS_BOOKER_BANNER,
  CMS_CASE_STUDIES,
  CMS_CONTENT,
  CMS_WEBSITE_MENU,
  CMS_FAQ_LIST,
  CMS_ROUTES,
  CMS_CONTENT_SPINNER,
  CMS_ROUTE_SEARCH,
  CMS_POPUPS,
  CMS_LOAD,
} from '../_types';

const { REACT_APP_CMS_ENDPOINT, REACT_APP_CMS_KEY } = process.env;

export const loadCMS = (x) => async (dispatch) => {
  const cms = {
    cmsHeader: {},
    cmsFooterCopyright: {},
    cmsWebsiteMenu: {},
    routeSearch: {},
  };

  // getRouteType
  try {
    const language = x.split('/')[1];
    const path = x.split('/');

    let count = 0;
    let newPath = path.length === 2 ? '/' : ''; //default value to handle route of "/:lang"
    path.forEach((path) => {
      if (count >= 2) {
        newPath = newPath + '/' + path;
      }
      count++;
    });

    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineContentSpinner?token=${REACT_APP_CMS_KEY}&filter[Path]=${newPath}&lang=${language}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Header.');
    const spinnerRequest = await response.json();
    const spinnerResults = { ...spinnerRequest };

    if (spinnerResults.total) {
      const payload = {
        type: 'shiftOnlineContentSpinner',
        ...spinnerResults.entries,
      };
      // dispatch({ type: CMS_ROUTE_SEARCH, payload });
      cms.routeSearch = payload;
    } else {
      const response2 = await fetch(
        `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineContent?token=${REACT_APP_CMS_KEY}&filter[Path]=${newPath}&lang=${language}`,
        { method: 'POST' }
      );

      if (response2.status !== 200)
        throw new Error('Failed to get CMS Header.');
      const contentRequest = await response2.json();
      const contentResults = { ...contentRequest };

      if (contentResults.total) {
        const payload = {
          type: 'shiftOnlineContent',
          ...contentResults.entries,
        };

        // dispatch({ type: CMS_ROUTE_SEARCH, payload });
        cms.routeSearch = payload;
      } else {
        const response3 = await fetch(
          `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineBlog?token=${REACT_APP_CMS_KEY}&filter[Path]=${newPath}&lang=${language}`,
          { method: 'POST' }
        );

        if (response3.status !== 200)
          throw new Error('Failed to get Blog Content.');
        const blogRequest = await response3.json();
        const blogResults = { ...blogRequest };

        if (blogResults.total) {
          const payload = {
            type: 'shiftOnlineBlog',
            ...blogResults.entries,
          };

          // dispatch({ type: CMS_ROUTE_SEARCH, payload });
          cms.routeSearch = payload;
        }
      }
    }
  } catch (error) {
    console.error(error);
    return error;
  }

  // getCmsHeader
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/singletons/get/shiftOnlineHeader?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Header.');
    const cmsHeader = await response.json();
    const payload = { ...cmsHeader };
    // dispatch({ type: CMS_HEADER, payload });
    cms.cmsHeader = payload;
  } catch (error) {
    console.error(error);
    return error;
  }
  // getCmsFooterCopyright
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/singletons/get/shiftOnlineFooter?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsFooterCopyright = await response.json();
    const payload = { ...cmsFooterCopyright };
    // dispatch({ type: CMS_FOOTERCOPYRIGHT, payload });
    cms.cmsFooterCopyright = payload;
  } catch (error) {
    console.error(error);
    return error;
  }
  // getCmsCollectionMenuHeader
  // Have to do it here because including it in theme, doesn't allow me to see any children, which is required for the Our Services page. - SM
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineHeaderMenu?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsWebsiteMenu = await response.json();
    const payload = { ...cmsWebsiteMenu };
    // dispatch({ type: CMS_WEBSITE_MENU, payload });
    cms.cmsWebsiteMenu = payload;
  } catch (error) {
    console.error(error);
    return error;
  }

  dispatch({ type: CMS_LOAD, payload: cms });
};

export const getCmsHeader = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/singletons/get/shiftOnlineHeader?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Header.');
    const cmsHeader = await response.json();
    const payload = { ...cmsHeader };
    dispatch({
      type: CMS_HEADER,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getRouteType = (x) => async (dispatch) => {
  try {
    const language = x.split('/')[1];
    const path = x.split('/');

    let count = 0;
    let newPath = '';
    path.forEach((path) => {
      if (count >= 2) {
        newPath = newPath + '/' + path;
      }
      count++;
    });

    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineContentSpinner?token=${REACT_APP_CMS_KEY}&filter[Path]=${newPath}&lang=${language}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Header.');
    const spinnerRequest = await response.json();
    const spinnerResults = { ...spinnerRequest };

    if (spinnerResults.total) {
      const payload = {
        type: 'shiftOnlineContentSpinner',
        ...spinnerResults.entries,
      };

      return dispatch({
        type: CMS_ROUTE_SEARCH,
        payload,
      });
    }

    const response2 = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineContent?token=${REACT_APP_CMS_KEY}&filter[Path]=${newPath}&lang=${language}`,
      { method: 'POST' }
    );

    if (response2.status !== 200) throw new Error('Failed to get CMS Header.');
    const contentRequest = await response2.json();
    const contentResults = { ...contentRequest };

    if (contentResults.total) {
      const payload = {
        type: 'shiftOnlineContent',
        ...contentResults.entries,
      };

      return dispatch({
        type: CMS_ROUTE_SEARCH,
        payload,
      });
    }

    const response3 = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineBlog?token=${REACT_APP_CMS_KEY}&filter[Path]=${newPath}&lang=${language}`,
      { method: 'POST' }
    );

    if (response3.status !== 200)
      throw new Error('Failed to get Blog Content.');
    const blogRequest = await response3.json();
    const blogResults = { ...blogRequest };

    if (blogResults.total) {
      const payload = {
        type: 'shiftOnlineBlog',
        ...blogResults.entries,
      };

      return dispatch({
        type: CMS_ROUTE_SEARCH,
        payload,
      });
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsCollectionServices = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineServices?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200)
      throw new Error('Failed to get CMS Collection Services.');
    const cmsCollectionServices = await response.json();
    const payload = { ...cmsCollectionServices };
    dispatch({
      type: CMS_COLLECTION_SERVICES,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsFaqList = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineFAQContent?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );
    if (response.status !== 200)
      throw new Error('Failed to get CMS FAQ Services.');
    const cmsFaqList = await response.json();
    const payload = { ...cmsFaqList };
    dispatch({
      type: CMS_FAQ_LIST,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsFooterCopyright = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/singletons/get/shiftOnlineFooter?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsFooterCopyright = await response.json();
    const payload = { ...cmsFooterCopyright };
    dispatch({
      type: CMS_FOOTERCOPYRIGHT,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsFooter = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineFooterMenu?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsFooter = await response.json();
    const payload = { ...cmsFooter };
    dispatch({
      type: CMS_FOOTER,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsCollectionBookingBanners = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/booker_banner?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsBookerBanner = await response.json();
    const payload = { ...cmsBookerBanner };
    dispatch({
      type: CMS_BOOKER_BANNER,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsCollectionCaseStudies = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/bookerCaseStudies?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsCaseStudies = await response.json();
    const payload = { ...cmsCaseStudies };
    dispatch({
      type: CMS_CASE_STUDIES,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsCollectionContent = (x) => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineContent?token=${REACT_APP_CMS_KEY}&filter[_id]=${x}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsContent = await response.json();
    const payload = { ...cmsContent };
    dispatch({
      type: CMS_CONTENT,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsCollectionSpinnerContent = (x) => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineContentSpinner?token=${REACT_APP_CMS_KEY}&filter[_id]=${x}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsContentSpinner = await response.json();
    const payload = { ...cmsContentSpinner };
    dispatch({
      type: CMS_CONTENT_SPINNER,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsCollectionMenuHeader = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineHeaderMenu?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get CMS Footer.');
    const cmsWebsiteMenu = await response.json();
    const payload = { ...cmsWebsiteMenu };
    dispatch({
      type: CMS_WEBSITE_MENU,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsRoutes = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineRoutes?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get Routes.');
    const cmsRoutes = await response.json();
    const payload = { ...cmsRoutes };
    dispatch({
      type: CMS_ROUTES,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getCmsPopups = () => async (dispatch) => {
  try {
    const response = await fetch(
      `${REACT_APP_CMS_ENDPOINT}/api/collections/get/shiftOnlineContentPopups?token=${REACT_APP_CMS_KEY}`,
      { method: 'POST' }
    );

    if (response.status !== 200) throw new Error('Failed to get popups.');
    const payload = await response.json();
    dispatch({
      type: CMS_POPUPS,
      payload,
    });
  } catch (error) {
    console.error(error);
    return error;
  }
};
