const heardAboutUs = [
  { id: 'Google', label: 'Google' },
  { id: 'Facebook', label: 'Facebook' },
  { id: 'Twitter', label: 'Twitter' },
  { id: 'Radio', label: 'Radio' },
  { id: 'TV', label: 'TV' },
  { id: 'Print', label: 'Print' },
  { id: 'Word of Mouth', label: 'Word of Mouth' },
  { id: 'Other', label: 'Other' },
];

export default heardAboutUs;
