import React, { useEffect, useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { hidePopup } from '../../../_actions/popup.action';
import { sleep } from '../functions';

// import closeIcon from '../assets/images/close.png';
import map from '../assets/images/Map.png';

const { REACT_APP_STRIPE_PK } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PK);

const useStyles = makeStyles({
  backgroundNoClick: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    backgroundColor: '#C8C8C866',
    zIndex: '100000',
  },
  root: {
    // overflowY: 'auto',
    backgroundColor: 'white',
    // maxWidth: 450,
    maxHeight: '90vh',
    position: 'fixed',
    color: '#737373',
    // padding: '20px 25px',
    borderRadius: 6,
    boxShadow: '0 5px 10px rgba(42, 40, 65, 0.12)',
    transition: 'all 0.75s cubic-bezier(0, 0.29, 0.29, 1.39) 0s',
    msTransition: 'all 0.75s cubic-bezier(0, 0.29, 0.29, 1.39) 0s',
    MozTransition: 'all 0.75s cubic-bezier(0, 0.29, 0.29, 1.39) 0s',
    WebkitTransition: 'all 0.75s cubic-bezier(0, 0.29, 0.29, 1.39) 0s',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    WebkitTransform: 'translate(-50%, -50%)',
    display: 'grid',
    gridTemplateRows: '1fr',
    height: 630,
    '@media only screen and (max-height: 568px)': {
      height: '100%',
    },
    '@media only screen and (max-width: 500px)': {
      transform: 'unset',
      msTransform: 'unset',
      WebkitTransform: 'unset',
      maxHeight: 'unset',
      width: '100vw',
      height: (state) => {
        if (state.cookieBannerVisible) {
          return 'calc(100% - ' + (state.cookieBannerHeight - 2) + 'px)';
        }

        return '100%';
      },
      borderRadius: 'unset',
      // padding: '10px',
      transition: 'all 0.4s',
      msTransition: 'all 0.4s',
      MozTransition: 'all 0.4s',
      WebkitTransition: 'all 0.4s',
    },
    '@media only screen and (min-width: 500px)': {
      width: 500,
    },
  },
  center: {
    top: '50%',
    left: '50%',
    opacity: 1,
    '@media only screen and (max-width: 500px)': {
      top: 0,
      left: 0,
      opacity: 1,
    },
  },
  left: {
    top: '50%',
    left: '20%',
    opacity: 0,
    '@media only screen and (max-width: 500px)': {
      top: 0,
      opacity: 1,
    },
  },
  right: {
    top: '50%',
    left: '80%',
    opacity: 0,
    '@media only screen and (max-width: 500px)': {
      top: 0,
      opacity: 1,
    },
  },
  bottom: {
    top: '80%',
    left: '50%',
    opacity: 0,
    '@media only screen and (max-width: 500px)': {
      left: 0,
      opacity: 1,
    },
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: 8,
    right: 10,
    fontSize: 20,
    zIndex: 999999,
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    borderRadius: 6,
  },
  icon: {
    fontSize: 32,
  },
  scrollingDiv: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
});

const Popup = (props) => {
  const {
    hidePopup,
    popup,
    style,
    cookieBannerHeight,
    cookieBannerVisible,
  } = props;

  const {
    showBgMap,
    showPopup,
    hideCloseButton,
    popUpBgColor,
    defaultOverflow,
    PopupComponent,
    popupPosition,
    popupCloseAction,
  } = popup;

  const classes = useStyles({ cookieBannerHeight, cookieBannerVisible });

  const [rootClassNames, setRootClassNames] = useState(
    classNames(classes.root, classes[popupPosition])
  );

  useEffect(() => {
    // ComponentDidMount
    const timer = setTimeout(() => {
      document.body.style.overflow = 'hidden';
      setRootClassNames(classNames(classes.root, classes.center));
    }, 100);
    return () => clearTimeout(timer);
  }, [classes.root, classes.center]);

  if (!showPopup) {
    return null;
  }

  const onClick = async (overridePosition) => {
    if (popupCloseAction) {
      popupCloseAction();
    }

    document.body.style.overflow = '';
    if (overridePosition) {
      if (overridePosition.target) {
        // Clicking the X icon causes an event to be passed.
        setRootClassNames(classNames(classes.root, classes[popupPosition]));
      } else if (
        overridePosition === 'bottom' ||
        overridePosition === 'left' ||
        overridePosition === 'right'
      ) {
        setRootClassNames(classNames(classes.root, classes[overridePosition]));
      } else {
        // Fallback
        setRootClassNames(classNames(classes.root, classes[popupPosition]));
      }
    } else {
      // Fallback
      setRootClassNames(classNames(classes.root, classes[popupPosition]));
    }

    await sleep(250);
    hidePopup();

    // TODO: Need to handle the Phone Capture Close somehow.
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            family: 'rawline',
            style: 'normal',
            weight: 400,
            src: `url(${process.env.PUBLIC_URL}/font/rawline-400.woff) format('woff')`,
          },
        ],
      }}
    >
      <div className={classes.backgroundNoClick}>
        <div className={classes.scrollingDiv}>
          <div
            className={rootClassNames}
            style={{
              backgroundColor: '#' + popUpBgColor,
              overflow: defaultOverflow,
              ...style,
            }}
          >
            {showBgMap && (
              <img src={map} className={classes.bgImage} alt='BGMap' />
            )}

            {!hideCloseButton && (
              <div className={classes.close} onClick={onClick}>
                <CloseIcon id={'popupIcon'} className={classes.icon} />
              </div>
            )}

            {cloneElement(PopupComponent, { handleClickClose: onClick })}
          </div>
        </div>
      </div>
    </Elements>
  );
};

Popup.propTypes = {
  position: PropTypes.oneOf(['bottom', 'right', 'left']),
  hidePopup: PropTypes.func.isRequired,
  style: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    popup: state.popup,
    cookieBannerHeight: state.cookieBanner.height,
    cookieBannerVisible: state.cookieBanner.visible,
  };
};
const mapDispatchToProps = { hidePopup };

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
