import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import classNames from 'classnames';

import Spinner from '../../../utils/spinner';
// import { icon } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles({
  root: {
    backgroundColor: (state) => state.theme.PrimaryColour,
    borderRadius: 8,
    // transition: 'all .3s ease-in-out',
    // msTransition: 'all .3s ease-in-out',
    // MozTransition: 'all .3s ease-in-out',
    // WebkitTransition: 'all .3s ease-in-out',
    cursor: 'pointer',
    boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.03)',
    color: 'white',
    display: 'grid',
    gridTemplateColumns: '1fr',
    textAlign: ({ textAlign }) => (textAlign ? textAlign : 'center'),
    alignItems: 'center',
    position: 'relative',
    fontSize: 16,
    lineHeight: '20px',
    padding: '0 20px',
    height: ({ height }) => (height ? height : '55px'),
    marginTop: 0,
    width: ({ width }) => (width ? width : 'auto'),
    '&:hover': {
      backgroundColor: (state) => state.theme.OnHoverColour2,
      // boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
  },
  arrow: {
    position: 'absolute',
  },
  arrowRight: {
    right: 15,
  },
  arrowLeft: {
    left: 15,
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
    '&:hover': {
      transform: 'none',
    },
  },
});

const PurpleButton = (props) => {
  const {
    text,
    arrowType,
    onClick,
    disabled,
    loading,
    theme,
    style,
    className,
    icon,
  } = props;

  const classes = useStyles(props, { theme });

  const handleClick = () => {
    if (disabled !== true && loading !== true) {
      onClick();
    }
  };

  const displayArrow = () => {
    const getClassNames = () => {
      if (arrowType === 'right') {
        return classNames(classes.arrow, classes.arrowRight);
      }

      return classNames(classes.arrow, classes.arrowLeft);
    };

    const getIcon = () => {
      if (icon) {
        return icon;
      } else {
        return arrowType === 'right' ? faArrowRight : faArrowLeft;
      }
    };

    if (!arrowType || arrowType === 'none') return null;

    return (
      <div className={getClassNames()}>
        <FontAwesomeIcon icon={getIcon()} />
      </div>
    );
  };

  const displayText = () => <div>{text}</div>;

  const displayButton = () => {
    if (loading) {
      return <Spinner height={18} width={18} />;
    }

    return (
      <Fragment>
        {displayText()}
        {displayArrow()}
      </Fragment>
    );
  };

  const getRootClassNames = () => {
    if (disabled) {
      return classNames(classes.root, classes.disabled, className);
    }

    return classNames(classes.root, className);
  };

  return (
    <div className={getRootClassNames()} onClick={handleClick} style={style}>
      {displayButton()}
    </div>
  );
};

PurpleButton.defaultProps = {
  textAlign: 'center',
};

PurpleButton.propTypes = {
  text: PropTypes.string.isRequired,
  arrowType: PropTypes.oneOf(['left', 'right', 'none']),
  onClick: PropTypes.func.isRequired,
  width: PropTypes.any,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { theme } = state.config;
  return { theme };
};
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(PurpleButton);
