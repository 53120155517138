import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const useStyles = makeStyles(styles);

const AdditionalHeader = React.memo((props) => {
  const { show, text } = props;
  const classes = useStyles();
  const [textHidden, setTextHidden] = useState(true);

  useEffect(() => {
    setTextHidden(true);
    const timeout = setTimeout(function() {
      setTextHidden(false);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [text]);

  const hiddenfaded = textHidden
    ? { opacity: 0, transition: 'all 0.2s linear' }
    : { opacity: 1, transition: 'all 0.2s linear' };

  if (!show) {
    return null;
  }

  return (
    <div className={[classes.subheader, 'theme_subheader'].join(' ')}>
      <Grid
        container
        classes={{ container: classes.container }}
        justify='center'
        alignItems='center'
      >
        <Grid item>
          <Typography
            variant='body1'
            classes={{ body1: classes.text }}
            style={hiddenfaded}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
});

const mapStateToProps = (state) => ({
  text: state.summaryReducer.additionalHeader.text,
  show: state.summaryReducer.additionalHeader.show,
});

export default connect(mapStateToProps, null)(AdditionalHeader);
