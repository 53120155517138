import {
  CHANGE_POPUP_BG_COLOR,
  CHANGE_POPUP_OVERFLOW,
  HIDE_POPUP,
  SET_POPUP,
  SHOW_POPUP,
  SHOW_BG_MAP,
  HIDE_CLOSE_BUTTON,
} from '../_types/popup.type';

export const changePopUpBg = (payload) => (dispatch) => {
  dispatch({
    type: CHANGE_POPUP_BG_COLOR,
    payload,
  });
};

export const changePopupOverflow = (payload) => (dispatch) => {
  dispatch({
    type: CHANGE_POPUP_OVERFLOW,
    payload,
  });
};

export const hidePopup = () => (dispatch) => {
  dispatch({
    type: HIDE_POPUP,
  });
};

export const setPopup = (payload) => (dispatch) => {
  dispatch({
    type: SET_POPUP,
    payload,
  });
};

export const showPopup = (payload) => (dispatch) => {
  dispatch({
    type: SHOW_POPUP,
    payload,
  });
};

export const showBgMap = () => (dispatch) => {
  dispatch({
    type: SHOW_BG_MAP,
  });
};

export const hideCloseButton = () => (dispatch) => {
  dispatch({
    type: HIDE_CLOSE_BUTTON,
  });
};
