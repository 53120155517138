import * as React from 'react';

function HelpComment(props) {
  return (
    <svg
      {...props}
      aria-hidden='true'
      data-prefix='fas'
      data-icon='comments-alt'
      viewBox='0 0 576 512'
      className='prefix__svg-inline--fa prefix__fa-comments-alt prefix__fa-w-18 prefix__fa-7x'
      width='24px'
      margin-top='-13px'
    >
      <path d='M416 224V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v160c0 35.3 28.7 64 64 64v54.2c0 8 9.1 12.6 15.5 7.8l82.8-62.1H352c35.3.1 64-28.6 64-63.9zm96-64h-64v64c0 52.9-43.1 96-96 96H192v64c0 35.3 28.7 64 64 64h125.7l82.8 62.1c6.4 4.8 15.5.2 15.5-7.8V448h32c35.3 0 64-28.7 64-64V224c0-35.3-28.7-64-64-64z' />
    </svg>
  );
}

export default HelpComment;
