import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isMobilePhone from 'validator/lib/isMobilePhone';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';

import FieldInput from '../FieldInput';
import PurpleButton from '../PurpleButton';
import Spinner from '../../../../utils/spinner';

import { updateTicket } from '../../../../_actions/ticket.action';
import { createOrGetCustomer, isMobile, sleep } from '../../functions';
import { smartlookTrack } from '../../../../_actions/smartlook.action';

import countries from '../../assets/js/countries';
import heardAboutUs from './assets/js/heardAboutUs';

import styles from './assets/js/styles';

const useStyles = makeStyles(styles);

const CapturePhonePopup = (props) => {
  const {
    handleClickClose,
    ticket,
    theme,
    updateTicket,
    smartlookTrack,
  } = props;

  const classes = useStyles({ theme });

  const [phoneCode, setPhoneCode] = useState(countries[0].id);
  const [phone, setPhone] = useState('');
  const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
  const [phoneSpinner, setPhoneSpinner] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [heardAboutUsVal, setHeardAboutUsVal] = useState('');
  const [loading, setLoading] = useState(false);

  // meta tracking
  useEffect(() => {
    smartlookTrack(null, { ticket, visited: 'phone_number_entry' });
  }, [ticket, smartlookTrack]);

  useEffect(() => {
    smartlookTrack({
      type: 'Phone Number Opened',
      ticketId: ticket.id,
    });
    /* window.smartlook('track', 'Phone Number Opened', {
      ticket: ticket.id
    }); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocusPhone = () => setPhoneErrorMsg('');

  const handleChangePhoneCode = (event) => setPhoneCode(event.target.value);
  const handleChangeHeardAboutUs = (event) => {
    setHeardAboutUsVal(event.target.value);
  };

  const phoneVerification = (value) => {
    let newValue = value.replace(/[^\d]/g, '');
    if (newValue.length === 0) {
      setPhoneSpinner(false);
    } else if (!isMobilePhone(newValue)) {
      setPhoneSpinner(true);
    } else {
      setTimeout(() => {
        setPhoneSpinner(false);
        setPhoneVerified(true);
      }, 300);
    }
  };

  const debouncePhoneVerification = useCallback(
    debounce(phoneVerification, 750),
    []
  );

  const handleChangePhone = (event) => {
    let { value } = event.target;
    if (value) {
      value = value.replace(/\D+/g, '');
    }
    setPhoneVerified(false);
    setPhoneSpinner(true);
    debouncePhoneVerification(value);
    setPhoneErrorMsg('');
    setPhone(value);
  };

  const handleSubmit = async () => {
    try {
      const { id } = ticket;
      setLoading(true);
      await sleep(500);
      const errorOccurred = [phoneError()];
      if (errorOccurred.includes(true)) {
        setLoading(false);
        setPhoneSpinner(false);
        setPhoneVerified(false);
        return;
      }

      const country = countries.find((country) => phoneCode === country.id);
      const countryCode = country ? country.value : '';
      const customerPayload = {
        Phone: phone,
        Account: 'Normal',
        country_code: countryCode ? countryCode : '',
        country_code_id: phoneCode ? phoneCode : '',
      };

      const { id: customerId } = await createOrGetCustomer(customerPayload);
      const ticketPayload = {
        phone,
        customerId,
        countryCode: countryCode ? countryCode : '',
        countryCodeId: phoneCode ? phoneCode : '',
        marketing_source: heardAboutUsVal,
      };

      setTimeout(() => {
        smartlookTrack({
          type: 'Phone Number Submitted',
          ticketId: id,
          number: phone,
          customerId: customerId,
        });
        /* window.smartlook('track', 'Phone Number Submitted', {
          number: phone,
          customerId: customerId,
          ticket: id,
        }); */
      }, 300);

      await updateTicket(id, ticketPayload, true);

      handleClickClose('bottom');
      await sleep(250);
    } catch (error) {
      console.error(error);
    }
  };

  const phoneError = () => {
    if (isEmpty(phone) || !phoneVerified) {
      if (isMobile()) {
        setPhoneErrorMsg('Invalid Phone');
      } else {
        setPhoneErrorMsg('A valid phone is required');
      }
      return true;
    }

    return false;
  };

  const renderValuePhoneCode = () => {
    const { id, value: code } = countries.find(
      (country) => country.id === phoneCode
    );
    return (
      <div id={'phoneCodeDiv'} className={classes.phoneCodeDiv}>
        <div id={'phoneCodeIcon'} className={classes.phoneCodeIcon}>
          <img
            src={`${
              process.env.PUBLIC_URL
            }/images/flags/${id.toLowerCase()}.svg`}
            width={20}
            alt='Flag'
          />
        </div>
        <div>{code}</div>
      </div>
    );
  };

  const phoneNumberLabelText = () => {
    if (window.innerWidth <= 320) return 'Phone Number';
    return 'Enter phone number';
  };

  return (
    <div id={'CapturePhonePopUpRoot'} className={classes.root}>
      <div id={'CapturePhonePopUpPaddingFix'} className={classes.paddingFix}>
        <div
          id={'CapturePhonePopUpTitleSubtitleDiv'}
          className={classes.titleSubtitleDiv}
        >
          <h1>Please enter your phone number for instant prices</h1>
        </div>
        <div id={'CapturePhonePopDetailsDiv'} className={classes.detailsDiv}>
          <div
            id={'CapturePhonePopUpDescription'}
            className={classes.description}
          >
            <p>Entering your phone number will reveal:</p>
            <ul>
              <li>The lowest prices, guaranteed!</li>
              <li>The simplest booking procedure!</li>
              <li>Important info about Shift!</li>
            </ul>
          </div>
          <div
            id={'CapturePhonePopUpPhoneInput'}
            className={classes.phoneInput}
          >
            <FieldInput
              labelText='Int. Code'
              onChange={handleChangePhoneCode}
              value={phoneCode}
              select
              selectItems={countries}
              renderValue={renderValuePhoneCode}
            />
            <FieldInput
              labelText={phoneNumberLabelText()}
              onChange={handleChangePhone}
              value={phone}
              helperText={phoneErrorMsg}
              onFocus={handleFocusPhone}
              inputType='tel'
              endAdornment={
                <InputAdornment
                  position='end'
                  classes={{ root: classes.inputAdornment }}
                >
                  {phoneSpinner && <Spinner height={18} width={18} />}
                  {phoneVerified && <CheckIcon color='inherit' />}
                </InputAdornment>
              }
            />
          </div>
          <Grid container direction='column'>
            <Grid item>
              <FieldInput
                labelText='How did you hear about us?'
                onChange={handleChangeHeardAboutUs}
                value={heardAboutUsVal}
                select
                selectItems={heardAboutUs}
              />
            </Grid>
          </Grid>
          <div className={classes.submitButton}>
            <PurpleButton
              text='View Prices Now!'
              arrowType='none'
              onClick={handleSubmit}
              textAlign='center'
              disabled={!phoneVerified}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CapturePhonePopup.propTypes = {
  handleClickClose: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired,
  updateTicket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const ticket = state.bookerTicket;
  const { theme } = state.config;
  return { ticket, theme };
};
const mapDispatchToProps = { updateTicket, smartlookTrack };

export default connect(mapStateToProps, mapDispatchToProps)(CapturePhonePopup);
