const styles = {
  container: {
    padding: '9px 15px',
  },
  text: {
    fontFamily: `'rawline', arial`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(51, 61, 85, 0.75)',
  },
  subheader: {
    boxShadow: '0 5px 10px rgba(42,40,65,.12)',
    backgroundColor: 'white',
  }
};

export default styles;
