import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import * as Sentry from '@sentry/react';
// Sentry.init({ dsn: "https://03a38a725b06451db4007b8538f91ab1@o245675.ingest.sentry.io/5354529" });

const browserSupportsAllFeatures = () => {
  return Boolean(
    window.Promise &&
      window.fetch &&
      window.Object.assign &&
      window.Symbol &&
      window.Array.from
  );
};

if (browserSupportsAllFeatures) {
  ReactDOM.render(<App />, document.getElementById('root'));
  serviceWorker.unregister();
} else {
  import('react-app-polyfill/ie11').then(() => {
    import('react-app-polyfill/stable').then(() => {
      ReactDOM.render(<App />, document.getElementById('root'));
      serviceWorker.unregister();
    });
  });
}
