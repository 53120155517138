import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import app from './_reducers/app.reducer';
import auth from './_reducers/auth.reducer';
import * as inventoryListReducer from './_reducers/inventory.reducers';
import pricePlansReducer from './_reducers/pricePlans.reducer';
import ticketReducer from './_reducers/ticket.reducer';
import uiReducer from './_reducers/ui.reducer';
import shiftShopReducer from './_reducers/shiftShop.reducer';
import timeslotReducer from './_reducers/timeslots.reducer';
import summaryReducer from './_reducers/summary.reducer';
import bookerTicketReducer from './_reducers/bookerTicket.reducer';
import popupReducer from './_reducers/popup.reducer';
import cmsReducer from './_reducers/cms.reducer';
import sideCartReducer from './_reducers/sideCart.reducer';
import themeReducer from './_reducers/theme.reducer';
import locationReducer from './_reducers/location.reducer';
import capturePhoneReducer from './_reducers/capturePhone.reducer';
import servicesReducer from './_reducers/services.reducer';
import snippitReducer from './_reducers/snippit.reducer';
import cookieBannerReducer from './_reducers/cookieBanner.reducer';

const rootreducer = combineReducers({
  inventoryList: combineReducers(inventoryListReducer),
  pricePlans: pricePlansReducer,
  timeslots: timeslotReducer,
  ticket: ticketReducer,
  ui: uiReducer,
  shiftShop: shiftShopReducer,
  cms: cmsReducer,
  serviceAvailability: servicesReducer,
});

const store = createStore(
  combineReducers({
    app,
    auth,
    rootreducer,
    summaryReducer,
    bookerTicket: bookerTicketReducer,
    popup: popupReducer,
    sideCart: sideCartReducer,
    config: themeReducer,
    location: locationReducer,
    capturePhone: capturePhoneReducer,
    snippit: snippitReducer,
    cookieBanner: cookieBannerReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
