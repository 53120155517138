/* global google */
const getDirectionsServiceRoute = (request) => {
  const directionsService = new google.maps.DirectionsService();
  return new Promise((resolve) => {
    directionsService.route(request, (response, status) => {
      resolve({ response, status });
    });
  });
};

export default getDirectionsServiceRoute;