import {
  CHANGE_POPUP_BG_COLOR,
  CHANGE_POPUP_OVERFLOW,
  HIDE_POPUP,
  SET_POPUP,
  SHOW_POPUP,
  SHOW_BG_MAP,
  HIDE_CLOSE_BUTTON,
} from '../_types/popup.type';

const initialState = {
  defaultOverflow: 'inherit',
  popUpBgColor: '#ffffff',
  PopupComponent: null,
  popupPosition: 'bottom',
  popupCloseAction: null,
  showBgMap: false,
  showPopup: false,
  hideCloseButton: false,
};

const uiReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_POPUP_BG_COLOR: {
      return {
        ...state,
        popUpBgColor: payload,
      };
    }

    case CHANGE_POPUP_OVERFLOW: {
      return {
        ...state,
        defaultOverflow: payload,
      };
    }

    case HIDE_POPUP: {
      return {
        ...state,
        showPopup: false,
        showBgMap: false,
        hideCloseButton: false,
        popupCloseAction: null,
      };
    }

    case SET_POPUP: {
      return {
        ...state,
        PopupComponent: payload,
      };
    }

    case SHOW_POPUP: {
      let popupPosition = 'bottom';
      let popupOverflow = 'inherit';
      let popupCloseAction = null;

      if (payload) {
        if (typeof payload === 'string') {
          popupPosition = payload;
        } else {
          if (payload.position) popupPosition = payload.position;
          if (payload.overflow) popupOverflow = payload.overflow;
          if (payload.closeAction) popupCloseAction = payload.closeAction;
        }
      }

      return {
        ...state,
        showPopup: true,
        popupPosition: popupPosition,
        popupCloseAction: popupCloseAction,
        defaultOverflow: popupOverflow,
      };
    }

    case SHOW_BG_MAP: {
      return {
        ...state,
        showBgMap: true,
      };
    }

    case HIDE_CLOSE_BUTTON: {
      return {
        ...state,
        hideCloseButton: true,
      };
    }

    default:
      return state;
  }
};

export default uiReducer;
