import React, { useImperativeHandle, useState } from 'react';

const StripeInput = (props) => {
  const {
    component: Component,
    inputRef,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    placeholder,
    ...other
  } = props;

  const [mountNode, setMountNode] = useState(null);

  useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  );

  // const origin =
  //   process.env.NODE_ENV !== 'production'
  //     ? 'https://shift.online'
  //     : `${window.location.origin}`;

  return (
    <Component
      onReady={setMountNode}
      options={{
        style: {
          base: {
            fontSize: '16px',
            color: '#A0A0A0',
            fontFamily: `"rawline"`,
            height: '100%',
            fontWeight: 400,
          },
          invalid: {
            color: '#A0A0A0',
          },
        },
        placeholder: placeholder || '',
      }}
      {...other}
    />
  );
};

export default StripeInput;
