import { SIDECART_HIDE, SIDECART_SHOW, SIDECART_TOGGLE } from '../_types';

const initialState = {
  open: false,
};

const sideCart = (state = initialState, action) => {
  switch (action.type) {
    case SIDECART_HIDE:
      return {
        open: false,
      };

    case SIDECART_SHOW:
      return {
        open: true,
      };

    case SIDECART_TOGGLE:
      return {
        open: state.open === false,
      };

    default:
      return state;
  }
};

export default sideCart;
