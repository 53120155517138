import isEmpty from 'lodash/isEmpty';

const getTotalItems = (assets) => {
  if (isEmpty(assets)) return '0';
  let totalItems = 0;
  for (let i = 0; i < assets.length; i++) {
    const { quantity } = assets[i];
    totalItems += quantity;
  }

  return totalItems;
};

export default getTotalItems;
