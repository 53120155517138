export const ISLOADING = 'ISLOADING';
export const ISERROR = 'ISERROR';

//INVENTORY LIST API
export const INVENTORYLISTAPI = 'INVENTORYLISTAPI';

// TICKET
export const GET_TICKET = 'GET_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const GET_BOOKER_TICKET = 'GET_BOOKER_TICKET';
export const GET_BOOKER_TICKET_SUB = 'GET_BOOKER_TICKET_SUB';
export const UPDATE_BOOKER_TICKET = 'UPDATE_BOOKER_TICKET';

// PRICE PLAN
export const GET_PRICE_PLANS = 'GET_PRICE_PLANS';
export const REPLACE_PRICE_PLANS = 'REPLACE_PRICE_PLANS';

// TIMESLOTS
export const SET_TIMESLOTS = 'SET_TIMESLOTS';
export const UPDATE_TIMESLOTS = 'UPDATE_TIMESLOTS';

// UI
export const CMS_LOAD = 'CMS_LOAD';
export const CMS_THEME_LOAD = 'CMS_THEME_LOAD';
export const SET_CURRENT_BOOKER_STEP = 'SET_CURRENT_BOOKER_STEP';
export const SET_TWO_SHIFTERS = 'SET_TWO_SHIFTERS';
export const CMS_HEADER = 'CMS_HEADER';
export const CMS_COLLECTION_SERVICES = 'CMS_COLLECTION_SERVICES';
export const CMS_FOOTER = 'CMS_FOOTER';
export const CMS_FOOTERCOPYRIGHT = 'CMS_FOOTERCOPYRIGHT';
export const CMS_CASE_STUDIES = 'CMS_CASE_STUDIES';
export const CMS_BOOKER_BANNER = 'CMS_BOOKER_BANNER';
export const CMS_CONTENT = 'CMS_CONTENT';
export const CMS_CONTENT_SPINNER = 'CMS_CONTENT_SPINNER';
export const CMS_WEBSITE_MENU = 'CMS_WEBSITE_MENU';
export const CMS_FAQ_LIST = 'CMS_FAQ_LIST';
export const CMS_ROUTES = 'CMS_ROUTES';
export const CMS_ROUTE_SEARCH = 'CMS_ROUTE_SEARCH';
export const CMS_POPUPS = 'CMS_POPUPS';
export const TOGGLE_STEPPER_OPACITY = 'TOGGLE_STEPPER_OPACITY';
export const SET_ADDITIONAL_HEADER = 'SET_ADDITIONAL_HEADER';
export const SET_NAV_PILL = 'SET_NAV_PILL';
export const SET_CASE_STUDIE = 'SET_CASE_STUDIE';
export const INIT_FOOTER_ACTION = 'INIT_FOOTER_ACTION';
export const SET_TEXT_FOOTER_ACTION = 'SET_TEXT_FOOTER_ACTION';
export const SET_FOOTER_HIDDEN_ACTION = 'SET_FOOTER_HIDDEN_ACTION';
export const SET_BUTTON_ACTION_FOOTER_ACTION =
  'SET_BUTTON_ACTION_FOOTER_ACTION';
export const SET_BUTTON_DISABLED_FOOTER_ACTION =
  'SET_BUTTON_DISABLED_FOOTER_ACTION';
export const SET_BUTTON_SPINNING_FOOTER_ACTION =
  'SET_BUTTON_SPINNING_FOOTER_ACTION';
export const SET_FOOTER_ACTION_MAX_WIDTH = 'SET_FOOTER_ACTION_MAX_WIDTH';

//Tracking
export const TRACKING = 'TRACKING';

// SHIFT SHOPPING
export const INITIALISE_SHIFT_SHOP_CART = 'INITIALISE_SHIFT_SHOP_CART';
export const SET_SHIFT_SHOP_CART = 'SET_SHIFT_SHOP_CART';
export const SET_DELIVERY_SLOTS = 'SET_DELIVERY_SLOTS';
export const SET_SELECTED_DELIVERY_SLOT_ID = 'SET_SELECTED_DELIVERY_SLOT_ID';
export const SET_SELECTED_DELVIERY_DATE = 'SET_SELECTED_DELVIERY_DATE';
export const SET_STORES = 'SET_STORES';
export const SET_SELECTED_STORE = 'SET_SELECTED_STORE';
export const SET_THEME = 'SET_THEME';

export const INIT_HEADER_LINK_ACTION = 'INIT_HEADER_LINK_ACTION';

// SmartLook Integration
export const SMARTLOOK_INIT = 'SMARTLOOK_INIT';
export const SMARTLOOK_TRACK = 'SMARTLOOK_TRACK';
export const SMARTLOOK_DISABLE = 'SMARTLOOK_DISABLE';

// SideCart
export const SIDECART_HIDE = 'SIDEBART_HIDE';
export const SIDECART_SHOW = 'SIDECART_SHOW';
export const SIDECART_TOGGLE = 'SIDECART_TOGGLE';

// Ikea Locations
export const LOCATION_GET_IKEA_STORES = 'LOCATION_GET_IKEA_STORES';
export const LOCATION_SET_IKEA_STORES = 'LOCATION_SET_IKEA_STORES';
export const LOCATION_SET_PICKUP = 'LOCATION_SET_PICKUP';
export const LOCATION_SET_DROPOFF = 'LOCATION_SET_DROPOFF';
export const LOCATION_SHOW_MOBILE_INPUT = 'LOCATION_SHOW_MOBILE_INPUT';

export const SET_COCKPIT_THEME = 'SET_COCKPIT_THEME';

// Capture Phone
export const CAPTURE_PHONE_START = 'CAPTURE_PHONE_START';
export const CAPTURE_PHONE_SET = 'CAPTURE_PHONE_SET';
export const CAPTURE_PHONE_ERROR = 'CAPTURE_PHONE_ERROR';
export const CAPTURE_PHONE_END = 'CAPTURE_PHONE_END';

// Check services
export const CHECK_SERVICES_START = 'CHECK_SERVICES_START';
export const CHECK_SERVICES_SET = 'CHECK_SERVICES_SET';
export const CHECK_SERVICES_ERROR = 'CHECK_SERVICES_ERROR';
export const CHECK_SERVICES_END = 'CHECK_SERVICES_END';

// Snippit
export const SNIPPIT_SET_PICKUP_DATA = 'SNIPPIT_SET_PICKUP_DATA';
export const SNIPPIT_SET_DROPOFF_DATA = 'SNIPPIT_SET_DROPOFF_DATA';
export const SNIPPIT_SET_ACTIVE_INPUT = 'SNIPPIT_SET_ACTIVE_INPUT';
export const SNIPPIT_SEARCH_START = 'SNIPPIT_SEARCH_START';
export const SNIPPIT_SEARCH_RESULTS = 'SNIPPIT_SEARCH_RESULTS';
export const SNIPPIT_SEARCH_END = 'SNIPPIT_SEARCH_END';

//Misc
export const SET_TIMESLOT_STRING = 'SET_TIMESLOT_STRING';
